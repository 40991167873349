import React from 'react'

import style from './a.module.scss'

//import assets
import calenderIcon from '../../assets/Icons/calenderIcon.svg'
import watchIcon from '../../assets/Icons/watchIcon.svg'
import locationIcon from '../../assets/Icons/locationIcon.svg'
import matIcon from '../../assets/Icons/matIcon.svg'
import { useNavigate } from 'react-router-dom'



const ClassCard = (props) => {
  console.log(props)
    let editClassURL =  window.location.href + "/" + props.id
    if(props.redirectURL){
        editClassURL = props.redirectURL
    } 
    const navigate = useNavigate()
    const getStyleForLevel = (level) => {
        let styleClass = '';
        if (level === 'Beginner') {
          styleClass = style.beginnerLevel;
        } else if (level === 'Intermediate') {
          styleClass = style.intermediateLevel;
        } else if (level === 'Advance') {
          styleClass = style.advancedLevel;
        }
        return styleClass;
      };
  return (
    // <div className={style.classCardComponent}>
    //     <div className={style.rightDetails}>
    //         <h2 >{props.title}</h2>
    //         <div>
    //             <img src={calenderIcon}></img>
    //             <p>{props.date}</p>
    //         </div>
    //         <div>
    //             <img src={watchIcon}></img>
    //             <p>{props.time}</p>
    //         </div>
    //         <div>
    //             <img src={matIcon}></img>
    //             <p>No. of mats : {props.matsLeft} left</p>
    //         </div>
    //         <div>
    //             <img src={locationIcon}></img>
    //             <h3 style={{fontWeight:"600"}}>{props.location}</h3>
    //         </div>
    //     </div>
    //     <div className={style.leftDetails}>
    //         <div className={`${style.level} ${getStyleForLevel(props.level)}`}>
    //             <p>{props.level}</p>
    //         </div>  

    //         <div className={style.priceDiv}>
    //             <h4>₹{props.price}</h4>
    //         </div>
    //         <a className={style.viewDetails} href={ editClassURL }>
    //             <h4><u>View details</u></h4>
    //         </a>
    //     </div>
    // </div>
    <div className={style.classCard}>
			<div className={style.classCardSubcomponent1}>
				<h2 >{props.title}</h2>
				<div>
					<img src={calenderIcon}></img>
					<p>{props.date}</p>
				</div>
				<div>
					<img src={watchIcon}></img>
					<p>{props.time} - {props.endTime}</p>
				</div>
				<div>
					<img src={matIcon}></img>
					<p>No. of mats : {props.matsLeft} left</p>
				</div>
				<div>
					<img src={locationIcon}></img>
					<h3 style={{fontWeight:"600"}}>{props.location}</h3>
				</div>
			</div>
			<div className={style.classCardSubcomponent2}>
				<div className={`${style.level} ${getStyleForLevel(props.level)}`}>
					<p>{props.level}</p>
				</div>  
				<div className={style.price}>
					<h4>₹{props.price}</h4>
				</div>
				<div className={style.viewDetails}>
					<a className={style.viewDetails} href={ editClassURL }>
						<h4><u>View details</u></h4>
					</a>
				</div>
			</div>
		</div>
  )
}

export default ClassCard;