import React from 'react'

import style from "./a.module.scss";

const TermsAndConditions = () => {
  return (
    <div className={style.overallPage}>
    <p>
    
    <h3>Terms & Conditions</h3>
    Welcome to CLASSMITRA LLP! We are a subscription and sale of service that provides our members a marketplace platform with access to profiles, schedule of teachers along with motion pictures, television and other audio-visual entertainment (“CLASSMITRA LLP content”) streamed over the Internet to certain Internet-connected TV’s, computers and other devices. We provide you with high quality video of yoga, music, dance, art, fitness classes, strength and stretch routines, foundational moves, information about fitness and bringing together a community of people that are interested in beginning a fitness routine for the first time or getting into shape after a long time away from fitness. This Website is not directed to persons under eighteen (18) years of age without parental supervision. The Website is owned by CLASSMITRA LLP. These Terms & Conditions of Use (this “Agreement”) apply to all of the products, services and websites offered by CLASSMITRA LLP, the mobile or tablet versions thereof, any CLASSMITRA LLP software, and any applications created by CLASSMITRA LLP whether available through a social networking site or its subsidiaries or affiliated companies. Please note that the availability of any CLASSMITRA LLP applications on a social networking site, mobile or tablet device, internet television or other technology platform does not indicate any relationship or affiliation between
     CLASSMITRA LLP and such social networking site, mobile or tablet device, or internet television or other technology platform.

     You have accepted these Terms of Use, which govern your use of our service. As used in these Terms of Use, “CLASSMITRA LLP service,” “our service” or “the service” means the service provided by CLASSMITRA LLP for discovering and watching CLASSMITRA LLP content, including all features and functionalities, 
     website, and user interfaces, as well as all content and software associated with our service.


     You agree to the Arbitration Agreement and class action waiver 
     described in this document to resolve any disputes with CLASSMITRA LLP (except for matters that may be taken to small claims court).
    
    <h4>Acceptance of Terms of Use</h4>

    <ul>
        <li>These Terms of Use, which include our Privacy Statement (https://classmitra.com/privacy/) govern your use of the CLASSMITRA LLP service. By using, visiting, or browsing the CLASSMITRA LLP service,
         you accept and agree to these Terms of Use. If you do not agree to these Terms of Use, do not use the CLASSMITRA LLP service.</li>
         <li>The CLASSMITRA LLP service is provided by CLASSMITRA LLP, Inc., or one of its affiliated companies.
         </li>
         <li>Changes to Terms of Use. CLASSMITRA LLP may, from time to time, change these Terms of Use, including the Privacy Statement and other. Such revisions shall be effective immediately; provided however, for existing members, such revisions shall, unless otherwise stated, be effective 30 days after posting. 
         We will endeavor to post prior versions of the Terms of Use, if any, for the preceding 12-month period.</li>
         <li>Privacy. Personally identifying information is subject to our Privacy Statement, the terms of which are incorporated herein. Please review our Privacy Statement to understand our practices.</li>
         <li>Communication Preferences. By using the CLASSMITRA LLP service, you consent to receiving electronic communications from CLASSMITRA LLP relating to your account. These communications may involve sending emails to your email address provided during registration, or posting communications on the CLASSMITRA LLP service, or in the “Profile” page and will include notices about your account (e.g., payment authorizations, change in password or Payment Method, confirmation e-mails and other transactional information) and are part of your relationship with CLASSMITRA LLP. You agree that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing. You should maintain copies of electronic communications by printing a paper copy or saving an electronic copy. You also consent to receiving certain other communications from us, such as newsletters about new CLASSMITRA LLP features and content, special offers, promotional announcements and customer surveys via email or other methods. If you no longer want to receive certain non-transactional communications, simply go to the “Communication settings” link on the “Profile” page of our website to manage your CLASSMITRA LLP communications.
          Please review our Privacy Statement (https://classmitra.com/privacy/) for further detail on our marketing communications.</li>
         <li>Membership and service and service, Free Trials, Billing and Cancellation</li>
         <li><h4>Membership and service
         </h4>
            <ol>
                <li>Ongoing Membership and service. Your CLASSMITRA LLP membership and service, which may start with a free trial, will continue month-to-month or year-to-year, based on your membership and service type and automatically renew unless and until you cancel your membership and service or we terminate it. You must have Internet access and provide us with a current, valid, accepted method of payment (as such may be updated from time to time, “Payment Method”) to use the CLASSMITRA LLP service. We will bill the monthly or yearly membership and service fee to your Payment Method. You must cancel your membership and service before it renews each month or each year in
                 order to avoid billing of the next months or next year's membership and service fees to your Payment Method.</li>
                <li>Differing Membership and services. We may offer a number of membership and service plans, including special promotional plans or membership and services with differing conditions and limitations. Any materially different terms from those described in these Terms of Use will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your membership and service with CLASSMITRA LLP by visiting our website and clicking on the “Profile” link. Some promotional membership and services are offered by third parties in conjunction with the provision of their own products and services. We are not responsible for the products and services provided by such third parties. 
                We reserve the right to modify, terminate or otherwise amend our offered membership and service plans.</li>
            </ol>

         </li>
         <li>Free Trials

            <ol>
                <li>Your CLASSMITRA LLP membership and service may start with a free trial. For combinations with other offers, 
                restrictions may apply. Free trials are for new members only. CLASSMITRA LLP reserves the right, in its absolute 
                discretion, to determine your free trial eligibility.</li>

                <li>We will begin billing your Payment Method for monthly or yearly membership and service fees at the end of the free trial period of your membership and service and your membership and service will automatically renew monthly or yearly at the start of each billing period, as noted in your “Profile” unless you cancel prior to the end of the free trial period. To view the specific details of your membership and service please reach out to our support teams by emailing: info@CLASSMITRA LLP.com or support@CLASSMITRA LLP.com. We may authorize your Payment Method through various methods, including authorizing it up to approximately one month of service as soon as you register. 
                In some instances, your available balance or credit limit may be reduced to reflect the authorization during your free trial period.</li>

                <li>CLICK THE “Profile” icon (image or person icon) TO FIND CANCELLATION INSTRUCTIONS. We will continue to bill your Payment Method on a monthly or annual basis (depending on your membership and service type) at the start of each billing period until your subscription has been cancelled,
                and no refunds will be provided for any reason.</li>


            
            </ol>
         </li>

         <li>Billing


            <ol>
                <li>Recurring Billing. By starting your CLASSMITRA LLP membership and service and providing or designating a Payment Method, you authorize us to charge you a monthly or yearly membership and service fee at the then current rate, and any other charges you may incur in connection with your use of the CLASSMITRA LLP service, such as taxes or possible transaction fees, to your Payment Method. You acknowledge that the amount billed each month or year may vary from month to month or year-to-year for reasons that may include differing amounts due to promotional offers, including gift card redemption and promotional code redemption, and/or changing or adding a plan, and you authorize us to charge your Payment Method for such varying amounts, 
                which may be billed monthly or yearly in one or more charges.</li>
                <li>Price Changes. We reserve the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any price changes to
                 your service will take effect following email notice to you.</li>
                 <li>Billing Cycle. The membership and service fee for our service will be billed at the beginning of the paying portion of your membership and service and each month or year thereafter unless and until you cancel your membership and service. We automatically bill your Payment Method each month or each year on the calendar day corresponding to the commencement of your paying membership and service. Membership and service fees are fully earned upon payment. We reserve the right to change the timing of our billing, in particular, as indicated below, if your Payment Method has not successfully settled. In the event your paying membership and service began on a day not contained in a given month, we may bill your Payment Method on a day in the applicable month or such other day as we deem appropriate. For example, if you started your CLASSMITRA LLP membership and service or became a paying member on January 31st, your next payment date is likely to be February 28th if paying monthly, and your Payment Method would be billed on that date. Your renewal date may change due to changes in your Membership and service. Visit our website and click on the “View billing details” link on the “Profile” page icon to see the commencement date for your next renewal period. We may authorize your Payment Method in anticipation of membership and service or service-related charges. As used in these Terms of Use, “billing” shall indicate a charge, debit or other payment clearance, as applicable, against your Payment Method.
                 Unless otherwise stated differently, month or monthly or yearly refers to your billing cycle.</li>
                 <li>No Refunds. PAYMENTS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY USED PERIODS. Following any cancellation, however, you will continue to have access to the service through the end of your current billing period. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members (“credits”). The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The provision of credits in one instance does not entitle you to credits in the future for similar instances,
                  nor does it obligate us to provide credits in the future, under any circumstance.</li>
                  <li>Payment Methods. You may edit your Payment Method information by visiting our website and clicking on the “Profile” icon link. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not edit your Payment Method information or cancel your account (see, “Cancellation” below), you remain responsible for any uncollected amounts and authorize us to continue billing the Payment Method, as it may be updated. This may result in a change to your payment billing dates. For certain Payment Methods, the issuer of your Payment Method may charge you a foreign transaction fee or other charges. 
                  Check with your Payment Method service provider for details.</li>
                  <li>Cancellation. You may cancel your CLASSMITRA LLP membership and service at any time, and you will continue to have access to the CLASSMITRA LLP service through the end of your monthly or yearly billing period. WE DO NOT PROVIDE REFUNDS OR CREDITS FOR ANY PARTIAL-MONTH OR PARTIAL YEAR MEMBERSHIP AND SERVICE PERIODS OR UNWATCHED CLASSMITRA LLP CONTENT nor do we retroact payment. To cancel, go to the “Profile” page found in your profile (see profile icon) on our website and follow the instructions for cancellation. If you cancel your membership and service, your account will automatically close at the end of your current billing period. To see when your account will close, click “View billing details” on the “Profile” page. If you signed up for CLASSMITRA LLP using your account with a third party as a Payment Method, and wish to cancel your CLASSMITRA LLP membership and service at any time, including during your free trial, you may need to do so through such third party, including by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from, the CLASSMITRA LLP service through that third party. You may also find billing information 
                  about your CLASSMITRA LLP membership and service by visiting your account with the applicable third party.</li>


            </ol>
         </li>

         <h4>CLASSMITRA LLP Service</h4>

         <li>You must be 18 years of age, or the age of majority in your province, territory or country, to become a member of the CLASSMITRA LLP service. Individuals under the age of 18, or applicable age of majority, may utilize the service only with the involvement of 
         a parent or legal guardian, under such person’s account and otherwise subject to these Terms of Use.</li>
         <li>The CLASSMITRA LLP service and any content viewed through our service are for your personal and non-commercial use only. During your CLASSMITRA LLP membership and service, we grant you a limited, non-exclusive, non-transferable, license to access the CLASSMITRA LLP service and view CLASSMITRA LLP content through the service. Except for the foregoing limited license,
          no right, title or interest shall be transferred to you. You agree not to use the service for public performances.</li>
         <li>You may view CLASSMITRA LLP content through the CLASSMITRA LLP service primarily within the country in which you have established your account and only in geographic locations where we offer our service and have licensed such CLASSMITRA LLP content. The content that may be available to watch will vary by geographic location. CLASSMITRA LLP will use 
         technologies to verify your geographic location. THE NUMBER OF DEVICES ON WHICH YOU MAY SIMULTANEOUSLY WATCH IS LIMITED.</li>
         <li>We continually update the CLASSMITRA LLP service, including the content library. In addition, we continually test various aspects of our service, including our website, user interfaces, service levels, plans, promotional features, availability of CLASSMITRA LLP content, delivery and pricing. We reserve the right to, and by using our service you agree that we may, include you in or exclude you from these tests without notice. We reserve the right in our sole and 
         absolute discretion to make changes from time to time and without notice in how we offer and operate our service.</li>
         <li>Some CLASSMITRA LLP content may become available for temporary download and offline viewing on certain supported devices (“Offline Titles”). Limitations apply, including restrictions on the number of Offline Titles per account, the maximum number of devices that can contain Offline Titles, the time period within which you will need to begin viewing Offline Titles and how long the Offline Titles will remain accessible. Some Offline Titles may not be playable in certain countries and if you go online in a country where you would 
         not be able to stream that Offline Title, the Offline Title will not be playable while you are in that country.</li>
         <li>You agree to use the CLASSMITRA LLP service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein. Except as explicitly authorized in these Terms of Use, you agree not to archive, download (other than through caching necessary for personal use), reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use content and information contained on or obtained from or through the CLASSMITRA LLP service without express written permission from CLASSMITRA LLP and its licensors. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the CLASSMITRA LLP service; use any robot, spider, scraper or other automated means to access the CLASSMITRA LLP service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the CLASSMITRA LLP service; insert any code or product or manipulate the content of the CLASSMITRA LLP service in any way; or, use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment
          associated with the CLASSMITRA LLP service, including any software viruses or any other computer code, files or programs.</li>
         <li>The availability of CLASSMITRA LLP content to watch will change from time to time, and from country to country. The quality of the display of the CLASSMITRA LLP content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. HD, Ultra HD and HDR availability is subject to your Internet service and device capabilities. Not all content is available in all formats, such as HD, Ultra HD or HDR and not all plans allow you to receive content in all formats. Default playback settings on cellular networks exclude HD, Ultra HD and HDR content. The minimum connection speed for SD quality is 0.5 Mbps; however, we recommend a faster connection for improved video quality. A download speed of at least 5.0 Mbps per stream is recommended to receive HD content (defined as a resolution of 720p or higher). A download speed of at least 25.0 Mbps per stream is recommended to receive Ultra HD (defined as a resolution of 1080p or higher) and HDR content. You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. CLASSMITRA LLP makes no representations or warranties about the quality of your watching experience on your display. The time it takes to begin watching CLASSMITRA LLP content show will vary based on a number of factors, including your location, available bandwidth at the time, 
         the CLASSMITRA LLP content you have selected and the configuration of your CLASSMITRA LLP ready device.</li>
         <li>CLASSMITRA LLP software is developed by, or for, CLASSMITRA LLP and is designed to enable streaming and viewing of content from CLASSMITRA LLP through CLASSMITRA LLP ready devices. This software may vary by device and medium, and functionalities may also differ between devices. BY USING OUR SERVICE, YOU AGREE TO RECEIVE, WITHOUT FURTHER NOTICE OR PROMPTING, UPDATED VERSIONS OF THE CLASSMITRA LLP AND RELATED THIRD-PARTY SOFTWARE. IF YOU DO NOT ACCEPT THE FOREGOING TERMS, DO NOT USE OUR SERVICE. WE DO NOT TAKE RESPONSIBILITY OR OTHERWISE WARRANT THE PERFORMANCE OF THESE DEVICES, INCLUDING THE CONTINUING COMPATIBILITY OF THE DEVICE WITH OUR SERVICE. By using our service, you agree to look solely to the entity that manufactured and/or sold you the device for any issues related to the device and its compatibility with the CLASSMITRA LLP service. If your CLASSMITRA LLP ready device is sold, lost or stolen, please deactivate the CLASSMITRA LLP ready device. If you fail to log out or deactivate your device, subsequent users may access the CLASSMITRA LLP service through your account and may be able to access certain of your account information.
          To deactivate a device, follow instructions on the “Profile” icon page of our website.</li>
         <li>We may terminate or restrict your use of our service, without compensation or notice if you are, or if we suspect that you are (i) in violation of any of these 
         Terms of Use or (ii) engaged in illegal or improper use of the service.</li>

         <h4>Passwords & Account Access</h4>
         <li>The member who created the CLASSMITRA LLP account and whose Payment Method is charged is referred to here as the Account Owner. The Account Owner has access and control over the CLASSMITRA LLP account. The Account Owner’s control is exercised through use of the Account Owner’s password and therefore to maintain exclusive control, the Account Owner should not reveal the password to anyone. In addition, if the Account Owner wishes to prohibit others from contacting CLASSMITRA LLP Customer Service and potentially altering the Account Owner’s control, the Account Owner should not reveal the Payment Method details (e.g., last four digits of their credit or debit card) associated with their account. You are 
         responsible for updating and maintaining the truth and accuracy of the information you provide to us relating to your account.​</li>
         <li>In order to provide you with ease of access to your account and to help administer the CLASSMITRA LLP service, CLASSMITRA LLP implements technology that enables us to recognize you as the Account Owner and provide you with direct access to your account without requiring you to retype any password or other user identification
          when you revisit the CLASSMITRA LLP service, which includes accessing via CLASSMITRA LLP ready devices or website.</li>
         <li>You should be mindful of any communication requesting that you submit credit card or other account information. Providing your information in response to these types of communications can result in identity theft. Always access your sensitive account information by going directly to the CLASSMITRA LLP website and not through a hyperlink in an email or any other electronic communication, even if it looks official. CLASSMITRA LLP reserves the right to place any account on hold anytime with or without notification to the member in order to protect itself and its partners from what it believes to be fraudulent activity. CLASSMITRA LLP is not obligated to credit or discount a membership and service for holds 
         placed on the account by either a representative of CLASSMITRA LLP or by the automated processes of CLASSMITRA LLP.</li>

         <h4>Disclaimers of Warranties and Limitations on Liability</h4>
         <li>THE CLASSMITRA LLP SERVICE AND ALL CONTENT AND SOFTWARE ASSOCIATED THERE WITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE CLASSMITRA LLP SERVICE, ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. CLASSMITRA LLP DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE CLASSMITRA LLP SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. CLASSMITRA LLP SPECIFICALLY DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS,
          CLASSMITRA LLP READY DEVICES, AND CLASSMITRA LLP SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).</li>
         <li>TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL CLASSMITRA LLP, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY)
          TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.</li>
         <li>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. 
         THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</li>
         <li>NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, 
         the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</li>
         <li>Gifts and Promotions. Gift cards for the CLASSMITRA LLP service may be available for purchase at third-party retail outlets and websites, which can be redeemed on our website. The purchase and redemption of gift cards are subject to the gift terms. From time to time, other types of promotional codes may be available, including those provided as part of a third party promotion. Promotional codes may be redeemed as described in the specifics of the promotion. Gift cards and promotional codes can only be used once, cannot be redeemed for cash, and may be combined with other offers, including a free trial, subject to certain restrictions as determined by CLASSMITRA LLP in its sole discretion. 
         If you received a promotional code through an offer by a third party, additional conditions may apply.</li>


         <h4>Intellectual Property</h4>

         <li>Copyright. The CLASSMITRA LLP service, including all content provided on the CLASSMITRA LLP service, 
         is protected by copyright, trade secret or other intellectual property laws and treaties.</li>
         <li>Claims of Copyright Infringement. If you believe your work has been reproduced or distributed in a way that constitutes a copyright infringement or are aware of any infringing material 
         available through the CLASSMITRA LLP service, please notify us by sending an email here: admin@classmitra.com</li>
         <li>Governing Law. Terms of Use shall be governed by and construed in accordance with the laws of the Government of India, INDIA. without regard to conflict of laws provisions. These terms will not limit any consumer protection rights that you may be entitled to under the mandatory laws of your state of residence. 
         This Agreement is subject to the exclusive jurisdiction and venue of the courts located in the city Pune, Maharashtra, India</li>
         <li>Applications. You may encounter third-party applications (including, without limitation, websites, widgets, software, or other software utilities) (“Application(s)”) that interact with the CLASSMITRA LLP service. These Applications may import data related to your CLASSMITRA LLP account and activity and otherwise gather data from you. These Applications are provided solely as a convenience to you, and CLASSMITRA LLP is not responsible for such Applications. SUCH APPLICATIONS ARE OWNED OR OPERATED BY THIRD PARTIES THAT ARE NOT RELATED TO WITH OR SPONSORED BY CLASSMITRA
          LLP AND MAY NOT BE AUTHORIZED FOR USE WITH OUR SERVICE IN ALL COUNTRIES. USE OF AN APPLICATION IS AT YOUR OWN OPTION AND RISK.</li>
         <li>Customer Support. To find more information about our service and its features, or if you need assistance with your account, please visit send us an email here: admin@classmitra.com . In certain instances, Customer Service may best be able to assist you by using a remote access support tool through which we have full access to your computer. If you do not want us to have this access, you should not consent to support through the remote access tool, and we will assist you through other means. In the event of any conflict between these Terms of Use
          and information provided by Customer Support or other portions of our website, these Terms of Use will control.</li>
         
         
         <h4>Arbitration Agreement
         </h4>

          <li>If you are a CLASSMITRA LLP member in India (including its possessions and territories), you and CLASSMITRA LLP agree that any dispute, claim or controversy arising out of or relating in any way to the CLASSMITRA LLP service, these Terms of Use and this Arbitration Agreement, shall be determined by binding arbitration or in consumer court. All arbitration or consumer court proceedings under this Agreement shall be held exclusively in Pune, Maharashtra, India. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator or court appointed Arbritrator instead of a judge. Arbritation allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. You agree that, by agreeing to these Terms of Use, the India arbitration and conciliation act governs the interpretation and enforcement of this provision, and that you and CLASSMITRA LLP are each waiving the right to a to participate in a class action lawsuit. This arbitration provision
           shall survive termination of this Agreement and the termination of your CLASSMITRA LLP membership and service.</li>
         <li>If you elect to seek arbitration or file a consumer court suit, you must first send to CLASSMITRA LLP, by certified mail, a written Notice of your claim (“Notice”). The Notice to CLASSMITRA LLP must be addressed to: CLASSMITRA LLP, office no 52 east street galleria, east street, camp, pune - 411001 maharashtra, India (“Notice Address”). If CLASSMITRA LLP initiates arbitration; it will send a written Notice to the email address used for your membership and service account. A Notice, whether sent by you or by CLASSMITRA LLP, must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). If CLASSMITRA LLP and you do not reach an agreement to resolve the claim within 30 days after
         the Notice is received, you or CLASSMITRA LLP may commence an arbitration proceeding or file a claim in consumer court.</li>
         <li>The arbitration will be governed by the arbitration and conciliation act. The arbitrator is bound by the terms of this Agreement. All issues
          are for the arbitrator to decide, including issues relating to the scope and enforceability of this arbitration agreement.    </li>
         <li>YOU AND CLASSMITRA LLP AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and CLASSMITRA LLP agree otherwise, the arbitrator may not consolidate more than one person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void. The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking
          relief and only to the extent necessary to provide relief warranted by that party’s individual claim.</li>
         <li>Survival. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal,
          or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</li>



          <h4>Waiver of Liability
          </h4>
          <li> You are accessing and using CLASSMITRA LLP content which is offered by CLASSMITRA LLP through its the service (collectively, the “Activities”).
          </li>
          <li>You recognize that you must be in adequate physical and mental health to participate in the Activities. You understand that the Activities may require intense physical exertion, and you represent and warrant that you are physically fit enough to participate and you have no medical condition which would prevent your full participation in the Activities. You recognize that the Activities may cause a physical injury or medical condition. You understand that it is your responsibility to consult with a physician before you participate in the Activities. If you have done so, you have taken the physician’s advice. You understand that 
          CLASSMITRA LLP reserves the right to refuse your participation in any Activity on medical, fitness or any other grounds.   </li>
          <li>You are aware that your participation in the Activities could result in high blood pressure, fainting, heartbeat disorders, physical injury, heart attack or stroke and may aggravate pre-existing injuries or conditions that you may have. You understand that you could experience muscle, back, neck and other injuries as a result of your participation in the Activities. You understand your physical limitations and you are sufficiently 
          self-aware to stop or modify your participation in any Activity before you become injured or aggravate a pre-existing injury.</li>
          <li>In consideration of being permitted to participate in the Activities, you agree to assume full responsibility for any risks, injuries or damages, known or unknown, which you might incur as a 
          result of participating in the Activities including those which may result from the negligence of CLASSMITRA LLP.</li>
          <li>In further consideration of being permitted to participate in the Activities, you knowingly, voluntarily and expressly waive any “Claim” (as defined below) you may have against CLASSMITRA LLP, its owners, managers, teachers, instructors, workshop presenters, employees, independent contractors and staff (each, a “Released Party”) that you may sustain as a result of participating in the Activities even if the Claim arises from the negligence of any Released Party or anyone else. You agree to indemnify and hold harmless each Released Party from any loss, cost, or liability incurred in defending any Claim made by you or anyone making a Claim on your behalf,
           even if the Claim is alleged to or did result from the negligence of any Released Party or anyone else.</li>
          <li>“Claim” includes but is not limited to any and all liabilities, claims, demands, expenses, fees, legal actions, rights of actions for damages, personal injury, mental suffering and distress, or death that you may suffer, you spouse, 
          children or unborn child may suffer (including any legal fees or expenses) in connection with participation in any Activity.</li>
          <li>You, your heirs or legal representatives forever release, waive, discharge 
          and covenant not to sue any Released Party for any Claim caused by any negligence or other acts of a Released Party.</li>
          <li>You acknowledge that you have carefully read this section of the Terms of Use and fully understand its contents. You voluntarily and knowingly agree to these Terms of Use. You are aware that by accepting these Terms of Use, you are giving up substantial rights, including your right to sue and 
          certain legal rights your heirs, next of kin, executors, administrators and assigns may have against any Released Party.</li>
          
          
          <h4>TERMS AND CONDITIONS FOR CLASSMITRA LLP APPS
          </h4>
          <li>Thanks for using the CLASSMITRA LLP App mobile app, “CLASSMITRA LLP App”. “CLASSMITRA LLP App” is provided by
           CLASSMITRA LLP. By using CLASSMITRA LLP App, you have understood and accepted the terms and conditions (Terms of Use).</li>
           


           <h4>BASIC TERMS OF USE</h4>
          <li>You are fully responsible for the information and any activity that occurs under your user account.</li>
          <li>You are fully responsible
           for protecting the password that you use to access CLASSMITRA LLP App and for any activities made under your password.</li>
          <li>You must not abuse, harass, threaten, impersonate or intimidate other CLASSMITRA LLP App users.</li>
          <li>You may not use CLASSMITRA LLP App for any illegal or unauthorized purpose.
          </li>
          <li>International users agree to comply with all local laws regarding online conduct and acceptable content.
          </li>
          <li>You are solely responsible for your conduct and any data, information, names, photos, profile information, links that you submit, post and display on CLASSMITRA LLP App.
          </li>

          <li>You must not copy, create a derivative work of, nor modify another app to falsely imply that it is associated with CLASSMITRA LLP App or any of the CLASSMITRA LLP App products or services.
          </li>
          <li>You must not interfere with or disrupt CLASSMITRA LLP App servers or networks such as the transmission of worms, viruses, or any code of a destructive nature applicable local, state, national, or international law.
          </li>
          <li>You must not, in using CLASSMITRA LLP App, intentionally or unintentionally violate any application local, state, national, or international law.
          </li>
          Violation of any of these agreements will result in the termination of your The CLASSMITRA LLP App account.

            <h4>GENERAL CONDITIONS</h4>
          <li>We reserve the right to modify or terminate the CLASSMITRA LLP App service for any reason, without notice at any time.
          </li>
          <li>CLASSMITRA LLP App reserves the right to change or modify these Terms of User at any time. Your continued use of CLASSMITRA LLP App will constitute your acceptance of such changes or modifications, what constitutes a “material change” will be determined at our sole discretion, in good faith and using common sense and reasonable judgment.
          </li>
          <li>We reserve the right to refuse service to anyone for any reasonable judgment.
          </li>
          <li>We may, but have no obligations to remove content and accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, and libelous. Defamatory, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.
              </li>
          <li>As a condition of your use of CLASSMITRA LLP App, you agree that we have the right, for any purpose, to retain, use and publish in an aggregate manner information collected from your user of the app.
          </li>

          <h4>SUBSCRIPTION AND CANCELLATION
          </h4>
          <li>Subscription membership and service purchases are made through Apple’s IAP (in app purchase) function or Google’s Google Play Store (in app purchase) and if you wish to terminate your subscription, you are required to do so via Apple’s or Google’s subscription platform.
          </li>
          <li>All purchases are final and no refund will be given for unused portions of your initial or any renewal membership and service and service period, live class or specialty course purchases.
          </li>

          <h4>INDEMNITY</h4>
          <li>You agree to indemnify CLASSMITRA LLP App on a full indemnity basis from any and all third party claims, liabilities, expenses including solicitor and client costs, that may be incurred or suffered as a result of a breach to any of these conditions set out in this Terms of Use by you or any third party damage caused as a result.
          </li>
          <li>You agree to indemnify CLASSMITRA LLP App from any liability, claim, harm, or expense arising from an injury or health problem that may arise from the use of CLASSMITRA LLP App.
          </li>

          <h4>DISCLAIMER</h4>
          The techniques and suggestions presented in The CLASSMITRA LLP App Application are not intended to substitute for proper fitness trainer, health professional or any and all medical advice. All fitness content and exercises have been created for information purposes only and you recognize that CLASSMITRA LLP App is not liable for any injuries or harm occurred by used of the exercise fitness information provided. Consult your physician before beginning any new exercise program.
           The CLASSMITRA LLP App Application assumes no responsibility for injuries suffered while practicing these techniques.
         </ul>
         </p>
    
    </div>
  )
}

export default TermsAndConditions