import { memo, useCallback, useEffect, useState } from "react";
import style from "../a.module.scss";

import { Field } from "../../../components/AppField";

import userProfileIllustration from '../../../assets/Illustrations/userProfile-illustrations.svg';
import arrowBack from '../../../assets/Icons/arrow-back.svg'
import AppPrimaryButton from "../../../components/AppPrimaryButton";

const Form = (props) => {
	return (
		<div className={style.editUserProfile}>
			<div className={style.title}>
				<h2>Edit Profile</h2>
			</div>
			
			<div className={style.userProfileForm}>
				<img src={userProfileIllustration}></img>
				<form onSubmit={props.onSubmit} noValidate>
					
					<div className={style.fullInput}>
						<Field
							labelText="Name"
							fieldType="text"
							fieldName="name"
							placeholder="Enter an name"
							fieldValue={props.values.name}
							hasError={props.errors.name}
							onFieldChange={props.onFieldChange}
						/>
					
						<Field
							labelText="Username"
							fieldType="text"
							fieldName="username"
							placeholder="Enter a username"
							fieldValue={props.values.username}
							hasError={props.errors.username}
							onFieldChange={props.onFieldChange}
						/>
						<Field
							labelText="Phone Number"
							fieldType="number"
							fieldName="phoneNumber"
							placeholder="Enter 10 digit mobile number "
							fieldValue={props.values.phoneNumber}
							hasError={props.errors.phoneNumber}
							onFieldChange={props.onFieldChange}
						/>

					</div>
						<button type="submit" className={style.createButton} style={{ color:"white",  border:"2px solid white" , fontWeight:"bold"}}>
							<AppPrimaryButton title="Save changes" fill={true}></AppPrimaryButton> 
						</button>
					
				</form>
			</div>
		</div>
	);
};
export default memo(Form);
