//import react
import React from "react";
//import styles
import style from "./a.module.scss";
const AppPrimaryButton = (props) => {
  //getting props
  const title = props.title;
  const fill = props.fill;
  const imageSrc = props.imageSrc
  const noBorder = props.noBorder

  const ImageComponent = ({imageSrc}) => {
    return (
      <div className={style.imageComponent}>
        <img src={imageSrc} alt="logo"></img>
      </div>
    )
  }
  //render
  return (
  <div className={fill==true ? style.appPrimaryFillButton : style.appPrimaryButton } style={{borderWidth:`${noBorder?"0px":"2px"}`}}>
      {imageSrc ? <ImageComponent imageSrc={imageSrc}/>:null}
      {title}
    </div>
  );
};

export default AppPrimaryButton;
