import React, { useEffect, useState } from "react";

import style from "./a.module.scss";

import ClassCard from "../../components/AppClassCard";
import { useParams } from "react-router-dom";
import { UserDetail } from "../../contexts/AppUserDetailContext";
import fetchUserClasses, { fetchUserDetails } from "./contexts";
import ClassDetails from "../ClassDetails";

const Series = () => {
  const { classType } = useParams();
  const { userName } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [userDetails, setUserDetails] = useState({
    id: "",
    name: "",
    username: "",
    email: "",
    seriesId: "",
    dropinId: "",
    phoneNumber: "",
  });

  const FetchUserDetails = async (userName) => {
    let query = {
      username: userName,
    };

    const res = await fetchUserDetails(query);
    const updatedValue = {
      id: res._id || "",
      name: res.name || "",
      username: res.username || "",
      email: res.email || "",
      seriesId: res.series_class_id || [],
      dropinId: res.dropin_class_id || [],
      phoneNumber: res.phone_number || "",
    };
    setUserDetails((userDetails) => ({
      ...userDetails,
      ...updatedValue,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    FetchUserDetails(userName);
  }, []);

  const [seriesClassDetail, setSeriesClassDetail] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const classesDetails = [];
      let lastFetchDone = false;
      console.log(userDetails)
      for (var i = 0; i < userDetails.seriesId.length; i++) {
        const classDetails = await fetchUserClasses(userDetails.seriesId[i],classType);
        console.log(classDetails)
        if (i == userDetails.seriesId.length - 1) {
          lastFetchDone = true;
        }
        classesDetails.push(classDetails[0]);
      }
      console.log(classesDetails)
      if (lastFetchDone) {
        setIsLoading(false);
      }
      setSeriesClassDetail(classesDetails);
    };
    fetchClasses();
  }, [userDetails]);

  if (isLoading) {
    return <>Loading ..... </>;
  }


  if (isLoading === false && seriesClassDetail.length == 0) {
    return <>No class created</>;
  }
  return (
    <div className={style.classesList}>
      {seriesClassDetail.map((card, index) => (
        <ClassCard
          id={card?._id}
          key={index}
          title={card?.name}
          date={card?.start_date}
          time={card?.start_time}
          endTime={card?.end_time}
        
          matsLeft={card?.number_of_mats_allowed}
          location={card?.location}
          level={card?.level}
          price={card?.price}
          username={userName}
        />
      ))}
    </div>
  );
};

export default Series;
