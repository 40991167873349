import { memo, useCallback } from "react";
import AppInputField from "../AppInputField";
import { bool } from "yup";

export const Field = memo((props) => {
	const onFieldChange = useCallback(
		(event) => {

		if (
			props.typeOfField == "datePicker" ||
			props.typeOfField == "timePicker"
		) {
			props.onFieldChange(props.fieldName, event);
		} else {
			props.onFieldChange(props.fieldName, event.target.value);
		}
		},
		[props.onFieldChange, props.fieldName]
	);
	return (
		<AppInputField
		name={props.name}
		label={props.labelText}
		hasError={props.hasError}
		labelText={props.labelText}
		id={props.fieldName}
		fieldName={props.fieldName}
		placeholder={props.placeholder}
		type={props.fieldType}
		value={props.fieldValue}
		onChange={onFieldChange}
		typeOfField={props.typeOfField}
		/>
	);
});
