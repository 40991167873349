import React from 'react'

import style from './a.module.scss';

const AppStatCard = (props) => {
    const color = props.background
    const colorTest = color?"#9b37F2":"white"
    const textColor = color?"white":"#393939"
  return (
    <div className={style.statCard} style={{backgroundColor:`${colorTest}`, color:`${textColor}`}} >
        <h2 style={{margin:"0.5em 0 0 0"}}>{props.value}</h2>
        <p>{props.title}</p>
    </div>
  )
}

export default AppStatCard;