import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../configs/EnvVariables";
import { getLocalValue } from "../utils/AppLocalStorage";

import { useContext, createContext, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { UserAuth } from "./AppFirebaseContext";
import { useLocation, useParams } from "react-router-dom";


const UserAboutContext = createContext(0)


const fetchUserDetails = async (query) => {
    try {
        const response = await axios.post(`${API_HOST}/api/user/search`, {
            query: query,
        });
        if (response && response.data) {
            return response.data[0]
        }
    } catch (error) {
        console.log(error);
    }
};


export const UserAboutContextProvider = ({ children }) => {

    const [isOtherUser, setIsOtherUser] = useState(true)

    const fetchUser = async (userName) => {
        if (userName) {
            const query = {
                "username": userName
            }
            const res = await fetchUserDetails(query);
            const email = getLocalValue('appUser').email;
            if (email == res.email) {
                setIsOtherUser(false)
            }
            else {
                setIsOtherUser(true)
            }
        }
        else {

        }
    }

    return (
        <UserAboutContext.Provider value={{ isOtherUser, fetchUser }}>
            {children}
        </UserAboutContext.Provider>
    )
}

export const UserAbout = () => {
    return useContext(UserAboutContext)
}
