//import react
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
//import styles
import style from "./a.module.scss";
//import app components
import AppPrimaryButton from "../../components/AppPrimaryButton";
import SetDetails from "./components/Details";
//imports from context
import { UserAuth } from '../../contexts/AppFirebaseContext';
import SignUp from "./components/SignUpDetails/SignUp"; 
//import assets

const UserSignUp = () => {
    const [componentToShow, setComponentToShow] = useState('signUp')
    const {page} = useParams()

	const navigate = useNavigate();

    //user authentication function 
    const {emailVerification, user} = UserAuth()
    
    //handling of sign in with social account
    const _handleEmailVerification = async () => {
        try{
            await emailVerification()
        }
        catch(error){
            console.log(error)
        }
    }

    const VerifyAccount = () => {
        // useEffect(()=>{
        //     const loaclUser = JSON.parse(localStorage.getItem('user'));
        //     if(!loaclUser){
        //         window.location.href="/signup/"
        //     }
        // },[user])

        return (
            <div>
                <h1>Verify the Account</h1>
                <div className={style.info}>An email has been sent to the {user?.email} with a link to verify your account.
                 If you have not received the email after a few minutes, please check your spam folder</div>
                <div style={{marginTop:'2em'}} onClick={_handleEmailVerification}><AppPrimaryButton title="Resend Email" fill={false}/></div>
            </div>
        )
    }
    
    const RenderComponent = () => {
        switch (page) {
            case undefined:
                return <SignUp />
            case 'verifyaccount':
                return <VerifyAccount />
            case 'details':
                return <SetDetails />
            default:
                <SignUp />
                break;

        }
    }

    //render
    return (
        <div className={style.userSignIn}>
            <RenderComponent />
        </div>
    );
};

export default UserSignUp;
