import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//styles
import style from './a.module.scss'

//assets
import homescreen from '../../assets/Illustrations/homescreens-illustrations.svg'
import greenTickIcon from '../../assets/Icons/greenTickIcon.svg'

//components
import Modal from "../../components/AppModal/Modal";
import AppPrimaryButton from "../../components/AppPrimaryButton";
import SubscribeUser from "./contexts";
import { UserAuth } from "../../contexts/AppFirebaseContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {

	const navigate = useNavigate();
	const [subMonth,setSubMonth] = useState(null);
	const {user} = UserAuth()

	useEffect(()=>{
		const Subscribe = async() => {
			if(subMonth==1){
				const res = await SubscribeUser(subMonth)
				if(res==true && subMonth!=null){
					toast.success('You have successfully subscribed to yogamitra for '+subMonth+" months");
				}
			}
			else{
				alert('You can only choose one month subscription');
			}
		}
		if(subMonth!=null){
			Subscribe()
		}
	},[subMonth])
	console.log(user)

	//home starting component
	const HomeIntro = () =>{
		return(
			<div className={style.home}>	
				<h1>Join Yogamitra, Unlock Benefits Today</h1>

				<h3 >Join the global movement for bringing Yoga to everyone</h3>
				{user.email? null:
				<div className={style.buttonDiv} onClick={() => navigate('/signin')}>
					<AppPrimaryButton title="Get Started" fill={true}/>
				</div>
				}
				<div className={style.imgDiv}>
					<img src={homescreen}/>
				</div>


			</div>
		)
	}
	const FreePricingCard = (props) =>{
		return(
			<div className={style.pricingCards}>	
				<div className={style.priceCard}>
					<p >{props.billedTitle}</p>
				<div className={style.blackLine}></div>
				<div className={style.oldPrice}>
					<p><s>{props.prevPrice}</s></p>
				</div>
				<div className={style.newPrice}>
					<p>{props.offerPrice}</p>
					<h3></h3>
				</div>
				<div className={style.cardButtonDiv} onClick={()=>setSubMonth(props.month)}>
					<AppPrimaryButton title="Get Started" fill={true}/>
				</div>
			</div>

			</div>
		)
	}
	const PricingCard = (props) =>{
		return(
			<div className={style.pricingCards}>	
				<div className={style.priceCard}>
					<p >{props.billedTitle}</p>
				<div className={style.blackLine}></div>
				<div className={style.oldPrice}>
					<p><s>{props.prevPrice}</s></p>
				</div>
				<div className={style.newPrice}>
					<p>{props.offerPrice}</p>
					<h3> /month</h3>
				</div>
				<div className={style.cardButtonDiv} onClick={()=>toast.success("This offer is unavailable right now")}>
					<AppPrimaryButton title="Get Started" fill={true}/>
				</div>
			</div>

			</div>
		)
	}


	const PremiumPricingCard = (props) =>{
		return(
			<div className={style.premiumPricingCards}>	
				<div className={style.premiumOffer}>
					<p>Best Value</p>
					<h3>Save 37%</h3>
				</div>
				<div className={style.priceCard}>
					<p >{props.billedTitle}</p>
				<div className={style.oldPrice}>
					<p><s>{props.prevPrice}</s></p>
				</div>
				<div className={style.newPrice}>
					<p>{props.offerPrice}</p>
					<h3> /month</h3>
				</div>
				<div className={style.cardButtonDiv} onClick={()=>toast.success("This offer is unavailable right now")}>
					<AppPrimaryButton title="Get Started" noBorder={true}/>
				</div>
			</div>

			</div>
		)
	}


	const Pricing =[
		{
			"Price":"₹ 15",
			"Duration":"1 month",
			"Details":"Unlimited classes"
		},
		{
			"Price":"₹ 35",
			"Duration":"3 month",
			"Details":"Unlimited classes"
		},
		{
			"Price":"₹ 55",
			"Duration":"6 month",
			"Details":"Unlimited classes"
		}
	]
	return (
		<div className={style.homePage}>
			<HomeIntro/>
			<p className={style.cardTitle}>Choose Your Plan</p>
			<div className={style.benefits}>
				<div className={style.benefitsLine}>
					<img className={style.greenTick} src={greenTickIcon}></img><p> Schedule in-person events</p>
				</div>
				<div className={style.benefitsLine}>
					<img className={style.greenTick} src={greenTickIcon}></img><p>  Mark attendance</p>
				</div>
				<div className={style.benefitsLine}>
					<img className={style.greenTick} src={greenTickIcon}></img><p> All private events</p>
				</div>
				<div className={style.benefitsLine}>
					<img className={style.greenTick} src={greenTickIcon}></img><p> Cash Payments available</p>
				</div>
				
			</div>
			<div className={style.pricingSection}>
				<FreePricingCard billedTitle="Billed Monthly" prevPrice="₹25" offerPrice="Free" month={1}/>
				<PricingCard billedTitle="Billed 3 Months" prevPrice="₹18" offerPrice="₹11" month={3}/>
				<PremiumPricingCard billedTitle="Billed 6 Months" prevPrice="₹14" offerPrice="₹9" month={6}/>
			</div>
			
			<div>
				{/* {Pricing.map((item,index)=>{
					return(
						<div key={index}>
							<p>{item.Price}</p>
							<p>{item.Duration}</p>
							<p>{item.Details}</p>
						</div>
					)
				})} */}
			</div>
		</div>
	)
};

export default Home;
