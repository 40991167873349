import { memo, useCallback, useEffect, useState } from "react";

import style from '../a.module.scss'

import Datepicker from "react-tailwindcss-datepicker";
import AppPrimaryButton from "../../../components/AppPrimaryButton";
import { Field } from "../../../components/AppField";

const DatePicker = (props) => {
  const [value, setValue] = useState("");

  const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;


  const onOptionChange = (e) => {
    setValue(e);
  };
  useEffect(() => {
    props.onFieldChange("date", value.startDate);
  }, [value]);
  return (
    <Datepicker
      asSingle={true}
      value={value}
      primaryColor={"purple" }
      onChange={onOptionChange}
      showFooter={true}
      useRange={false}
      popoverDirection="down"
      minDate={formattedDate}
    />
  );
};

export const Form = memo((props) => (
  <div className={style.createClassScheduler}>
    <form onSubmit={props.onSubmit} noValidate>
      <h1 className={style.fillTheseDetails}>Fill these details</h1>
      <div className={style.titleDatePicker}>
        <Field
          labelText="Title"
          fieldType="text"
          fieldName="title"
          placeholder="Enter class name"
          fieldValue={props.values.title}
          hasError={props.errors.title}
          onFieldChange={props.onFieldChange}
        />
		<div className={style.datePickerDiv}>

			<p>Select a date</p>
			<DatePicker
			onFieldChange={props.onFieldChange}
			fieldValue={props.values.date}
			/>
      {props.errors.date?<p style={{color:"red"}}>{props.errors.date}</p>:null}
		</div>
      </div>
        <div className={style.startEndTimePickers}>
        <Field
          labelText="Start time"
          fieldName="startTime"
          fieldValue={props.values.startTime}
          hasError={props.errors.startTime}
          onFieldChange={props.onFieldChange}
          typeOfField={"timePicker"}
        />
        <Field
          labelText="End time"
          fieldName="endTime"
          fieldValue={props.values.endTime}
          hasError={props.errors.endTime}
          onFieldChange={props.onFieldChange}
          typeOfField={"timePicker"}
        />
      </div>
      <button style={{ marginTop: "2em" }} type="submit">
        <AppPrimaryButton title="Continue" fill={true} />
      </button>
    </form>
  </div>
));