import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../../../configs/EnvVariables";
import { getLocalValue } from "../../../utils/AppLocalStorage";



const checkValuesInUserDb = async (query) => {
	try {
		const response = await axios.post(`${API_HOST}/api/user/search`, {
			query: query,
		});
		if (response && response.data.length>0) {
			return false
		}
        else{
            return true
        }
	} catch (error) {
		console.log(error);
	}
};

const saveDetials = async (formData) => {
    const userId = getLocalValue('appUser').id;
    formData["id"]=userId
	try {
		const response = await axios.put(`${API_HOST}/api/user`,
			formData
		);
        if(response.status === 202){
            return response.data
        }
        else{
            return false
        }
	} catch (error) {
		console.log(error);
	}
};

const getUserDetails = async (username) => {
  let apiUrl = `${API_HOST}/api/user/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
        "query": {
            "username": username
        }
    }
    ),
  };

  let resData ;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
        resData = error;
    });
    return resData
}

export {saveDetials, getUserDetails}

export default checkValuesInUserDb;