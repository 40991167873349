import React, { useEffect, useState } from "react";

import DropdownTableComponent from "./dropdownTableComponent";
import SeriesTableDateComponent from "../../ClassDetails/components/series/TableDateComponent";
import style from "../a.module.scss";
import { Picklist } from "react-rainbow-components";
import { Option } from "react-rainbow-components";
import fetchClassDays, { getClassDetails, getDayDetails, getUserDetails } from "../context";
import { useNavigate, useParams } from "react-router-dom";
import AppPrimaryButton from "../../../components/AppPrimaryButton";

const Dropdown = () => {
  const { id, classType } = useParams();
  const [classDetails, setClassDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [classDateDetails, setClassDateDetails] = useState([]);
  const [registeredUserDetails, setRegisteredUserDetails] = useState([])
  const [dayDetails, setDayDetails] = useState({});
  const navigate = useNavigate();


  const getUser = async (classDetails) => {
    const registered_users = classDetails.registered_user;
    const userDetail = [];
    for (let i = 0; i < registered_users.length; i++) {
      const res = await getUserDetails(registered_users[i]);
      userDetail.push(res[0]);
    }
	setRegisteredUserDetails(userDetail)
  };

  const FetchClassDetails = async () => {
    const class_res = await getClassDetails(id, classType);
    setClassDetails(class_res[0]);
	getUser(class_res[0])
  };

  const FetchClassDateDetails = async () => {
    const class_date_res = await fetchClassDays(
      id,
      classDetails.start_date,
      classDetails.end_date
    );
    setClassDateDetails(class_date_res);
    setSelectedDate({
      name: class_date_res[0]["date"],
      label: class_date_res[0]["date"],
    });
  };

  useEffect(() => {
    FetchClassDetails();
  }, []);

  useEffect(() => {
    if (classDetails._id) {
      FetchClassDateDetails();
    }
  }, [classDetails]);

  const containerStyles = {
    width: "200px",
  };

  const onDropDownChange = async (e) => {
    setSelectedDate({ name: e.name, label: e.label });
  };

  const fetchDayDetails = async () => {
    const day_res = await getDayDetails(id, selectedDate.name);
	setDayDetails(day_res[0])
  };

  useEffect(() => {
    if (selectedDate.name) {
      fetchDayDetails();
    }
  }, [selectedDate]);

  return (
    <div>
      <div className={style.dropdownComponent}>
        <Picklist
          style={containerStyles}
          onChange={onDropDownChange}
          value={selectedDate}
          label="Select Date"
          hideLabel
        >
          <Option name="header" label="Select Date" variant="header" />
          {classDateDetails.map((item, index) => {
            return <Option name={item.date} label={item.date} key={index} />;
          })}
        </Picklist>
      </div>
      {registeredUserDetails.length==0?
      <>
      <div style={{"margin":"2em 0"}}>
        Currently there is no registered user for this class
      </div>  
        <div onClick={() => navigate(-1)}>
          <AppPrimaryButton title="Back" fill={true} />
        </div>
      </>
      :
	  <div>
        <DropdownTableComponent registeredUserDetails={registeredUserDetails} dayDetails={dayDetails} fetchDayDetails={fetchDayDetails}/>
	  </div>
    }
    </div>
  );
};

export default Dropdown;
