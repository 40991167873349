import React, { useEffect, useState } from "react";
import RegisterUserComp from "./RegisterUserComp";
import RequestedUserComp from "./RequestedUserComp";

const SeriesTableComponent = (props) => {
  const isRegistrationSelected = props.isRegistrationSelected;
  const classDetails = props.classDetails
  return (
    <div>
      {isRegistrationSelected ? <RegisterUserComp classDetails={classDetails}/> : <RequestedUserComp classDetails={classDetails}/>}
    </div>
  )
}

export default SeriesTableComponent
