// import stuff from react library
import { createContext } from "react";
// import stuff from other js libraries
import axios from "axios";
// import app configs
import { API_HOST, API_VERSION } from "../../../configs/EnvVariables.js";
// import utils
import { setLocalValue, getLocalValue } from "../../../utils/AppLocalStorage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

async function getClassDetails(id, classType) {
  let apiUrl = `${API_HOST}/api/${classType}/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: {
        _id: id,
      },
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
}

async function getUserDetails(id) {
  let apiUrl = `${API_HOST}/api/user/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: {
        _id: id,
      },
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
}
const changeUserClassDetail= async (query, classType) => {
  let apiUrl = `${API_HOST}/api/${classType}`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(query),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
      toast.success("Information updated successfully")
    })
    .catch((error) => {
      resData = error;
      toast.error(error.message)

    });
  return resData;
};

const sendClassRequest = async (classType, classId, studentId) => {
  const data = {
    id: classId,
    requested_user: [studentId],
  };  
  let apiUrl = `${API_HOST}/api/${classType}`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log(options)
  let resData="";
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
      toast.error(error.message)
    });
  return resData;
};

const updateStudentClassRequest = async (classType, classId, studentId) => {
  const classTypeKey = `${classType}_requested_class_id`
  const data = {
    "id": studentId
  };
  data[classTypeKey] = [classId];
  let apiUrl = `${API_HOST}/api/user`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log(options)
  let resData="";
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
      toast.error(error.message)
    });
  return resData;
}; 


const deleteRequestClassFromUser = async (classType, classId, studentId) => {
  const deleteRequestClassKey = `delete_${classType}_requested_class_id`
  const registerClassKey = `${classType}_registered_class_id`
  const data = {
    "id": studentId
  };
  data[deleteRequestClassKey] = [classId];
  data[registerClassKey] = [classId];
  let apiUrl = `${API_HOST}/api/user`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  let resData="";
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
      toast.error(error.message)
    });
  return resData;
}; 


const getFilteredData = (class_date_res, start_date, end_date) => {
  const new_start_date = new Date(start_date)
  new_start_date.setDate(new_start_date.getDate() - 1);
  
  const sortedData = [...class_date_res].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  const today = new Date()
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const filteredData = sortedData.filter((item) => {
    const itemDate = new Date(item.date);
    const startDateObj = new Date(new_start_date);
    const endDateObj = new Date(end_date);
    return itemDate >= startDateObj && itemDate <= endDateObj;
  });

  return filteredData;
};

const fetchClassDays = async(id, startDate, endDate) => {
  const start_date = new Date(startDate);
  const end_date = new Date(endDate);
  let apiUrl = `${API_HOST}/api/day/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: {
        "series_id": id,
      },
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      const filteredData = getFilteredData(data, start_date, end_date);
      resData = filteredData;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
}

const deleteClass = async(classID, classType) => {
  let apiUrl = `${API_HOST}/api/${classType}`;
  const options = {
    method: "DELETE",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
        id: classID,
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
}

export { getUserDetails,sendClassRequest, changeUserClassDetail,deleteClass, updateStudentClassRequest, deleteRequestClassFromUser, fetchClassDays};
export default getClassDetails;
