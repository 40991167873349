import React, { useEffect, useState } from "react";
import style from "../../a.module.scss";
import AppPrimaryButton from "../../../../components/AppPrimaryButton";
import getClassDetails, {
  changeUserClassDetail,
  getUserDetails,
} from "../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const SeriesRegisterUserComp = (props) => {
  const classDetails = props.classDetails;
  const { id } = useParams();
  const { classType } = useParams();
  const navigate = useNavigate();
  const [registerUser, setRegisterUser] = useState([]);
  const [paidUser, setPaidUser] = useState([]);
  const [classPaidUsers, setClassPaidUsers] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async (classDetails) => {
    const registered_users = classDetails.registered_user;
    const paid_users = classDetails.paid_user || [];
    setClassPaidUsers(paid_users);

    const userDetail = [];
    for (let i = 0; i < registered_users.length; i++) {
      const res = await getUserDetails(registered_users[i]);
      userDetail.push(res[0]);
    }
    setRegisterUser(userDetail);
    setPaidUser(paid_users);
    setIsLoading(false);
    setIsSaveLoading(false);
  };
  const fetchClassDetails = async (id) => {
    const res = await getClassDetails(id, classType);
    getUser(res[0]);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchClassDetails(id);
    }
  }, []);

  const handlePaidUser = (userID) => {
    if (paidUser?.includes(userID)) {
      setPaidUser(paidUser.filter((number) => number !== userID));
    } else {
      setPaidUser([...paidUser, userID]);
    }
  };

  const handlePaidCheckboxChange = (event) => {
    if (event.target.checked) {
      let newArray = [];
      for (let i = 0; i < registerUser.length; i++) {
        newArray.push(registerUser[i]["_id"]);
      }
      setPaidUser(newArray);
    } else {
      setPaidUser([]);
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    let newPaidUser = [];
    let deletePaidUser = [];
    for (let i = 0; i < registerUser.length; i++) {
      const registeredId = registerUser[i]["_id"];
      if (
        !classPaidUsers?.includes(registeredId) &&
        paidUser?.includes(registeredId)
      ) {
        newPaidUser.push(registeredId);
      } else if (
        classPaidUsers?.includes(registeredId) &&
        !paidUser?.includes(registeredId)
      ) {
        deletePaidUser.push(registeredId);
      }
    }
    const query = {
      id: id,
      paid_user: newPaidUser,
      delete_paid_user: deletePaidUser,
    };
    const res = await changeUserClassDetail(query, classType);
    fetchClassDetails(id);
  };

  const convertJsonToExcel = (json) => {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    return url;
  };

  const downloadExcel = () => {
    let sheetData = [];

    for (let i = 0; i < registerUser.length; i++) {
      const user = registerUser[i];
      const userDetails = {
        "Name": user.name,
        "Phone Number": user.phone_number,
        "Username": user.username,
        "Email": user.email,
        "Is user verified": user.is_email_verified ? "Yes" : "No",
        "Paid": paidUser?.includes(user["_id"]) ? "Yes" : "No",
      };
      console.log(userDetails)
      sheetData.push(userDetails);
    }
    const url = convertJsonToExcel(sheetData);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${classDetails.name}-registered-${classType}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return <>Loading ..... </>;
  }
  if (registerUser == undefined || registerUser.length == 0) {
    return (
      <>
        <p style={{ margin: "2em 0" }}>No registered users</p>
      </>
    );
  }
  return (
    <div>
      <div className={style.checkboxButton}>
        <div className={style.checkboxes}>
          <div>
            <input
              type="checkbox"
              id="markAllPaid"
              name="markAllPaid"
              checked={registerUser?.length === paidUser?.length}
              onChange={handlePaidCheckboxChange}
            ></input>
            <label htmlFor="markAllPaid"> Mark all paid</label>
          </div>
        </div>
        <div className={style.checkboxSaveButton} onClick={() => handleSave()}>
          <AppPrimaryButton title="Save" />
        </div>
      </div>
      {isSaveLoading ? (
        <h1>Saving ... </h1>
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Student name</th>
                <th>Paid</th>
              </tr>
            </thead>
            <tbody>
              {registerUser.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/user/${data.username}`)}
                    >
                      {data.name}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={paidUser?.includes(data["_id"])}
                        onChange={() => handlePaidUser(data["_id"])}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div
            style={{ margin: "2em auto", padding: "0 2em", maxWidth: "20em" }}
            className={style.checkboxSaveButton}
            onClick={downloadExcel}
          >
            <AppPrimaryButton title="Download Student Data" fill={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SeriesRegisterUserComp;
