import { useParams } from "react-router-dom";

import Dropin from "./dropin";
import Series from "./series";

const CreateClassScheduler = () => {
	const {classType} = useParams()
	return (
		<div>
			{classType=='dropin'?<Dropin />:<Series />}
		</div>
	);
}

export default CreateClassScheduler;