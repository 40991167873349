import { CheckboxToggle } from "react-rainbow-components";

const ToggleButton = ({ value, onChange }) => {
  return (
    <CheckboxToggle
      id="checkbox-toggle-component-1"
      labelAlignment="left"
      value={value}
      onChange={onChange}
    />
  );
};

export default ToggleButton;