import React, { useEffect, useState } from "react";

import style from "./a.module.scss";
import { useParams } from "react-router-dom";
import Dropin from "./dropin";
import Series from "./series";

const ClassesList = () => {
  const { classType } = useParams();
  
  return (
    <div className={style.classesList}>
      {classType=="dropin"? <Dropin/>:<Series/>}
    </div>
  );
};

export default ClassesList;
