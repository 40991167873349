import React from 'react'

import style from'./a.module.scss'

const AppTextArea = ({ value, onChange }) => {
	return (
		<div className={style.appTextArea}>
			<div className={style.growWrap}>
				<textarea name="text" id="text" value={value} onChange={onChange} ></textarea>
			</div>
		</div>
	)
}

export default AppTextArea