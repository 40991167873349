import React, { useEffect, useState } from "react";
import { UserAuth } from "../../../contexts/AppFirebaseContext";
import style from "../a.module.scss";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "../hooks/useComponentVisible";
import { UserDetail } from "../../../contexts/AppUserDetailContext";
import { getLocalValue } from "../../../utils/AppLocalStorage";
import AppPrimaryButton from "../../AppPrimaryButton";
import AppLoader from "../../AppLoader";
// const { isAppLoading } = UserDetail();


const Menubar = () => {
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();
  const [showDisplay, setShowDisplay] = useState(false);
  const { userDetails,isAppLoading } = UserDetail(null);

  const _handleSignOut = async () => {
    try {
      await logOut();
      localStorage.removeItem("appUser");
      window.location.href="/"
    } catch (error) {
      console.log(error);
    }
  };

  const { ref, isComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    setShowDisplay(isComponentVisible);
  }, [isComponentVisible]);

  const MenuList = [
    {
      title: "View Profile",
      route: `/user/${userDetails?.username}`,
    },
    {
      title: "Create Classes",
      route: `/create`,
    },
    {
      title: "View dropin",
      route:
        window.location.protocol +
        "/" +
        userDetails?.username +
        "/class/dropin",
    },
    {
      title: "View Series",
      route:
        window.location.protocol +
        "/" +
        userDetails?.username +
        "/class/series",
    },
    {
      title: "Registered classes",
      route:
        window.location.protocol +
        "/" +
        userDetails?.username +
        "/class/registered",
    },
    {
      title: "Requested classes",
      route:
        window.location.protocol +
        "/" +
        userDetails?.username +
        "/class/requested",
    },
    {
      title: "Edit Profile",
      route: `/user/${userDetails?.username}/edit`,
    },
    {
      title: "Home",
      route: "/",
    },
  ];

  const LoginComp = () => {
    return(
      <div style={{cursor:"pointer" , width:"5em"}} onClick={()=>{navigate('/signin')}}>
        <AppPrimaryButton title="Login" fill={true}/>
      </div>
    )
  }

  if (isAppLoading) {
    return (
      <div style={{position:"relative", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <AppLoader />
      </div>
    );
  }

  if(getLocalValue("appUser") == undefined && user?.emailVerified ==undefined){
    return <LoginComp />
  }
  return (
    
    <div className={style.desktopMenu}>
      <div className={style.desktopMenuBackground} style={{display:`${showDisplay?"block":"none"}`}} onClick={() => setShowDisplay(!showDisplay)}>
      </div>
      {userDetails?.name ? (
        <div className={style.desktopMenuButton}>
          <h2 ><span className={style.menubarUserName} style={{color:"blue" }}>Hii, {userDetails?.name}</span></h2>
          <div onClick={() => setShowDisplay(!showDisplay)}>
            {userDetails?.name[0]}
          </div>
        </div>
      ) : 
      <div style={{cursor:"pointer"}} onClick={()=>navigate('/signup/details')}>
        Fill Details
      </div>
      }
      {showDisplay ? (
        <div
        className={style.menu}
          //   style={{ display: showDisplay ? "flex" : "none" }}
          ref={ref}
          >
          {MenuList.map((item, key) => {
            return (
              <div>
                <a href={item.route}>{item.title}</a>
              </div>
            );
          })}
          <div style={{ color: "grey" }} onClick={_handleSignOut}>
            Logout
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Menubar;
