import { memo, useCallback, useState } from "react";
import update from "immutability-helper";
import * as yup from "yup";


import { Form } from "./components/form";
import checkValuesInUserDb, { saveDetials } from "../../contexts";
import { useNavigate } from "react-router";


const SetDetails = memo(() => {

    const navigate = useNavigate()

    const CheckUsername = async (username) => {
        let query = {
            'username': username,
        }
        const res = await checkValuesInUserDb(query)
        return res
    }

    const CheckPhoneNumber = async (phoneNumber) => {
        let query = {
            'phone_number': phoneNumber,
        }
        const res = await checkValuesInUserDb(query)
        return res
    }

    const saveDetails = async (details) => {
        let formData = {
            'name': details.name,
            'username': details.username,
            'phone_number': details.phoneNumber,
        }
        const res = await saveDetials(formData)
        if(res){
            navigate(`/user/${res.username}`)
        }
    }

    
	const formSchema = yup.object().shape({
		name: yup.string().required('Name is required'),
		username: yup.string()
		.matches(/^[a-z0-9_]+$/, 'Username should only contain small alphabets, numbers, and underscores')
		.min(5, 'Username must be at least 5 characters long')
		.max(15, 'Username must be at most 15 characters long')
		.test('username validation', 'Username already exists', function(value) {
			return CheckUsername(value) || 'Username already exists';
		})
		.required('Username is required'),
		phoneNumber: yup
		.string()
		.matches(/^[0-9]{10}$/, 'Phone number should be exactly 10 digits')
		.test('phone number validation', 'Phone number already exists', function (value) {
			return CheckPhoneNumber(value) || 'Phone number already exists';
		})
		.required('Phone number is required'),
	});

	const [values, setValues] = useState({
		name: "",
		username: "",
		phoneNumber: "",
	});
	const [errors, setErrors] = useState({
		name: false,
		username: false,
		phoneNumber: false,
	});

	const onFieldChange = useCallback((fieldName, value) => {
		setValues((prevValues) =>
		update(prevValues, {
			[fieldName]: {
			$set: value,
			},
		})
		);
	}, []);

	const onSubmit = useCallback(
		async (event) => {
		event.preventDefault();
		const isFormValid = await formSchema.isValid(values, {
			abortEarly: false,
		});
		if (isFormValid) {
			saveDetails(values)
		} else {
			formSchema.validate(values, { abortEarly: false }).catch((err) => {
            const error = {};
            err.inner.forEach((err) => {
                error[err.path] = err.message;
            });
			setErrors((prevErrors) =>
				update(prevErrors, {
				$set: error,
				})
			);
			});
		}
		},
		[values]
	);

	return (
		<div>
			<Form
				values={values}
				errors={errors}
				onFieldChange={onFieldChange}
				onSubmit={onSubmit}
                formSchema={formSchema}
			/>
		</div>
	);
});

export default SetDetails;