import React, { useEffect, useState } from "react";

import { Field } from "../../components/AppField";
import AppPrimaryButton from "../../components/AppPrimaryButton";
import DateAndTimePickerComponent from "./components/dateAndTimePickerComponent";

import style from "./a.module.scss";
import fetchClassDays, { getClassDetails } from "./context";
import { useNavigate, useParams } from "react-router-dom";

const ClassDatesEdit = () => {
  const { id, classType } = useParams();
  const [classDetails, setClassDetails] = useState([]);
  const [classDateDetails, setClassDateDetails] = useState([]);
  const [changedDates, setChangedDates] = useState([]);
  const navigate = useNavigate();

  const FetchClassDetails = async () => {
    const class_res = await getClassDetails(id, classType);
    setClassDetails(class_res[0]);
  };

  const FetchClassDateDetails = async () => {
    const class_date_res = await fetchClassDays(
      id,
      classDetails.start_date,
      classDetails.end_date
    );
    setClassDateDetails(class_date_res);
  };

  useEffect(() => {
    FetchClassDetails();
  }, []);

  useEffect(() => {
    if (classDetails._id) {
      FetchClassDateDetails();
    }
  }, [classDetails]);

  return (
    <div className={style.classDateEdit}>
      {classDateDetails.length == 0 ? (
        <div>
          <h1>No upcoming dates to edit</h1>
        </div>
      ) : (
        <div>
          <h1>Edit time</h1>
          {classDateDetails.map((item, index) => {
            return (
              <DateAndTimePickerComponent
                data={item}
                key={index}
                changedDates={changedDates}
                setChangedDates={setChangedDates}
              />
            );
          })}
        </div>
      )}
      <div className={style.backAndSaveButtonDiv}>
        <div onClick={() => navigate(-1)}>
          <AppPrimaryButton title="Back" fill={true} />
        </div>
      </div>
    </div>
  );
};

export default ClassDatesEdit;
