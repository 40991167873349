import { useEffect } from "react";

import React from "react";

import style from "./a.module.scss";

import closeIcon from "../../assets/Icons/closeIcon.svg";

const MyModal = ({ closeModal, children, modelTitle }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {};
  }, []);

  return (
    <div>
      <div className={style.modalwrapper} onClick={closeModal}></div>
      <div className={style.modalcontainer}>
        <div className={style.modalHeader} style={{justifyContent: "space-between"}}>
          <h1>{modelTitle}</h1>
          <div className={style.closeButton} onClick={closeModal}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default MyModal;
