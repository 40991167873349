import React, { useEffect, useState } from "react";

import { Field } from "../../../components/AppField";

import style from "../a.module.scss";
import { TimePicker } from "react-rainbow-components";
import AppPrimaryButton from "../../../components/AppPrimaryButton";
import { updateDayTimings } from "../context";

const DateAndTimePickerComponent = (props) => {
  const { data } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [dateDetails, setDateDetails] = useState({
    date: data.date,
    start_time: data.start_time,
    end_time: data.end_time,
  });

  const containerStyles = {
    maxWidth: 200,
  };

  const onStartDateFieldChange = (e) => {
    setDateDetails({ ...dateDetails, start_time: e });
  };
  const onEndDateFieldChange = (e) => {
    setDateDetails({ ...dateDetails, end_time: e });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const updateDay = await updateDayTimings(
      data._id,
      dateDetails.start_time,
      dateDetails.end_time
    );
    setIsLoading(false);
  };

  return (
    <div className={style.dateAndTimePickerComponent}>
      <h3>{dateDetails.date}</h3>
      <div className={style.startEndTimePickers}>
        <TimePicker
          id="time-picker-1"
          value={dateDetails.start_time}
          onChange={onStartDateFieldChange}
          style={containerStyles}
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        <TimePicker
          id="time-picker-2"
          value={dateDetails.end_time}
          onChange={onEndDateFieldChange}
          style={containerStyles}
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
      </div>
      {isLoading ? (
        <>Saving ... </>
      ) : (
        <div className={style.saveButtonRightAliignment}>
          <div style={{ maxWidth: "5em" }} onClick={() => handleSave()}>
            <AppPrimaryButton title="Save" fill={false} />
          </div>
        </div>
          
      )}
    </div>
  );
};

export default DateAndTimePickerComponent;
