import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../../../configs/EnvVariables";
import { getLocalValue } from "../../../utils/AppLocalStorage";



const checkValuesInUserDb = async (query) => {
	try {
		const response = await axios.post(`${API_HOST}/api/user/search`, {
			query: query,
		});
		if (response && response.data.length>0) {
			return false
		}
        else{
            return true
        }
	} catch (error) {
		console.log(error);
	}
};

const saveDetials = async (formData) => {
    const userId = getLocalValue('appUser').id;
    formData["id"]=userId
	try {
		const response = await axios.put(`${API_HOST}/api/user`,
			formData
		);
		console.log(response);
        if(response.status === 202){
            return response.data
        }
        else{
            return false
        }
	} catch (error) {
		console.log(error);
	}
};

export {saveDetials}

export default checkValuesInUserDb;