// import stuff from react library
import { createContext } from "react";
// import stuff from other js libraries
import axios from "axios";
// import app configs
import { API_HOST, API_VERSION } from "../../../configs/EnvVariables.js";
// import utils
import { setLocalValue, getLocalValue } from "../../../utils/AppLocalStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

async function getClassDetails(id, classType) {
  let apiUrl = `${API_HOST}/api/${classType}/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: {
        _id: id,
      },
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
}

const getFilteredData = (class_date_res, start_date, end_date) => {
  const new_start_date = new Date(start_date)
  new_start_date.setDate(new_start_date.getDate() - 1);
  
  const sortedData = [...class_date_res].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  const today = new Date()
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const filteredData = sortedData.filter((item) => {
    const itemDate = new Date(item.date);
    const startDateObj = new Date(new_start_date);
    const endDateObj = new Date(end_date);
    return itemDate >= startDateObj && itemDate <= endDateObj;
  });

  return filteredData;
};

const fetchClassDays = async (id, startDate, endDate) => {
  const start_date = new Date(startDate);
  const end_date = new Date(endDate);
  let apiUrl = `${API_HOST}/api/day/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: {
        series_id: id,
      },
    }),
  };

  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      const filteredData = getFilteredData(data, start_date, end_date);
      resData = filteredData;
    })
    .catch((error) => {
      resData = error;
    });
  return resData;
};

const updateDayTimings = async (id, start_time, end_time) => {
  const data = {
    id: id,
    start_time: start_time,
    end_time: end_time,
  };

  let apiUrl = `${API_HOST}/api/day`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
      toast.success("Successfully saved")
    })
    .catch((error) => {
      resData = error;
      toast.success(error.message)

    });
  return resData;
};



const getDayDetails = async (id, date) => {
    let apiUrl = `${API_HOST}/api/day/search`;
    const options = {
      method: "POST",
      url: apiUrl,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: {
          series_id: id,
          date: date
        },
      }),
    };
  
    let resData;
    await fetch(apiUrl, options)
      .then((response) => response.json())
      .then((data) => {
        resData = data;
      })
      .catch((error) => {
        resData = error;
      });
    return resData;
  };
  
  async function getUserDetails(id) {
    let apiUrl = `${API_HOST}/api/user/search`;
    const options = {
      method: "POST",
      url: apiUrl,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: {
          _id: id,
        },
      }),
    };
  
    let resData;
    await fetch(apiUrl, options)
      .then((response) => response.json())
      .then((data) => {
        resData = data;
      })
      .catch((error) => {
        resData = error;
      });
    return resData;
  }

  
const changeUserClassDayDetail= async (query) => {
  let apiUrl = `${API_HOST}/api/day`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(query),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
      toast.success("Information updated successfully")
    })
    .catch((error) => {
      resData = error;
      toast.error(error.message)

    });
  return resData;
};

export { getClassDetails, updateDayTimings , getDayDetails, getUserDetails, changeUserClassDayDetail};
export default fetchClassDays;
