import { memo, useCallback, useEffect, useState } from "react";

import style from '../a.module.scss'

import Datepicker from "react-tailwindcss-datepicker";
import AppPrimaryButton from "../../../components/AppPrimaryButton";
import { Field } from "../../../components/AppField";

const DatePicker = (props) => {
  const [value, setValue] = useState("");
  
  
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;

const [minEndDate, setMinEndDate] = useState(formattedDate); // New state for minimum end date

const onOptionChange = (e) => {
  setValue(e);
};

useEffect(() => {
  if (props.fieldName === 'startDate') {
    props.onFieldChange(props.fieldName, value.startDate);
  } else {
    props.onFieldChange(props.fieldName, value.endDate);
  }
}, [value]);

return (
  <Datepicker
    asSingle={true}
    value={value}
    primaryColor={"purple" }
    onChange={onOptionChange}
    showFooter={true}
    useRange={false}
    popoverDirection="down"
    minDate={minEndDate} // Use minEndDate here
  />
);
};

export const SeriesForm = memo((props) => (
  <div className={style.createClassScheduler}>
    
    <form onSubmit={props.onSubmit} noValidate>
      <h1 className={style.fillTheseDetails}>Fill these details</h1>
      <div className={style.titleDatePicker}>
        <Field
          labelText="Series title"
          fieldType="text"
          fieldName="title"
          placeholder="Enter class name"
          fieldValue={props.values.title}
          hasError={props.errors.title}
          onFieldChange={props.onFieldChange}
        />
        <div className={style.startEndDatePickersDiv}>
            <div className={style.seriesDatePickerDiv}>

                <p>Start date</p>
                <DatePicker
                onFieldChange={props.onFieldChange}
                fieldValue={props.values.startDate}
                fieldName="startDate"
                />
                {props.errors.startDate?<p style={{color:"red"}}>{props.errors.startDate}</p>:null}

            </div>
            <div className={style.seriesDatePickerDiv}>

                <p>End date</p>
                <DatePicker
                onFieldChange={props.onFieldChange}
                fieldValue={props.values.endDate}
                fieldName="endDate"
                startDate={props.values.startDate}
                />
                {props.errors.endDate?<p style={{color:"red"}}>{props.errors.endDate}</p>:null}
            </div>
        </div>
      </div>
        <div className={style.startEndTimePickers}>
        <Field
          labelText="Start time"
          fieldName="startTime"
          fieldValue={props.values.startTime}
          hasError={props.errors.startTime}
          onFieldChange={props.onFieldChange}
          typeOfField={"timePicker"}
        />
        <Field
          labelText="End time"
          fieldName="endTime"
          fieldValue={props.values.endTime}
          hasError={props.errors.endTime}
          onFieldChange={props.onFieldChange}
          typeOfField={"timePicker"}
        />
      </div>
      <button style={{ marginTop: "2em" }} type="submit">
        <AppPrimaryButton title="Continue" fill={true} />
      </button>
    </form>
  </div>
));
