let API_HOST = ''
let APP_ENV = process.env.REACT_APP_ENV
let GA4_ID = ''
let FRONTEND_HOST = ''

console.log(process.env.REACT_APP_ENV)
//SETTING BACKEND URL
switch (APP_ENV) {
    case 'local':
        console.log('connecting to local api')
        API_HOST = 'http://localhost:4242'
        FRONTEND_HOST = 'https://localhost:3000'
        break;
    case 'dev':
        console.log('connecting to dev api')
        API_HOST = 'https://yogamitra-dev-backend.vercel.app'
        FRONTEND_HOST = 'https://yogamitra-prev.vercel.app'
        break;
    case 'test':
        console.log('connecting to test api')
        API_HOST = 'https://yogamitra-dev-backend.vercel.app'
        FRONTEND_HOST = 'https://yogamitra-prev.vercel.app'
        break;
    case 'prod':
        console.log('connecting to prod api')
        API_HOST = 'https://yogamitra-live-backend.vercel.app'
        FRONTEND_HOST = 'https://www.yogamitra.in'
        break;
    default:
        console.log('connecting to dev default api')
        API_HOST = 'https://yogamitra-dev-backend.vercel.app'
        FRONTEND_HOST = 'https://yogamitra-prev.vercel.app'
        break;
}

let FIREBASE_API_KEY = ""
let FIREBASE_AUTH_DOMAIN = ""
let FIREBASE_PROJECT_ID = ""
let FIREBASE_STORAGE_BUCKET = ""
let FIREBASE_MESSAGING_SENDER_ID = ""
let FIREBASE_APP_ID = ""
let FIREBASE_MEASUREMENT_ID = ""
//SETTING FIREBASE URL
switch (APP_ENV) {
    case 'local':
        console.log("connected to firebase local")
        FIREBASE_API_KEY = "AIzaSyA5TCs8861BbWd6X9tqS3ltGK834Fx0QyU"
        FIREBASE_AUTH_DOMAIN = "yogamitra-ee5f5.firebaseapp.com"
        FIREBASE_PROJECT_ID = "yogamitra-ee5f5"
        FIREBASE_STORAGE_BUCKET = "yogamitra-ee5f5.appspot.com"
        FIREBASE_MESSAGING_SENDER_ID = "926744598582"
        FIREBASE_APP_ID = "1:926744598582:web:25cab08bc66b6fa437e097"
        // FIREBASE_MEASUREMENT_ID = "G-GL3EWFRK85"
        break;
    case 'dev':
        console.log("connected to firebase dev")
        FIREBASE_API_KEY = "AIzaSyA5TCs8861BbWd6X9tqS3ltGK834Fx0QyU"
        FIREBASE_AUTH_DOMAIN = "yogamitra-ee5f5.firebaseapp.com"
        FIREBASE_PROJECT_ID = "yogamitra-ee5f5"
        FIREBASE_STORAGE_BUCKET = "yogamitra-ee5f5.appspot.com"
        FIREBASE_MESSAGING_SENDER_ID = "926744598582"
        FIREBASE_APP_ID = "1:926744598582:web:25cab08bc66b6fa437e097"
        break;
    case 'prod':
        console.log("connected to firebase prod")
        FIREBASE_API_KEY = "AIzaSyBkN2p3ap11LJqaJklGot-UyucFvDg9c94"
        FIREBASE_AUTH_DOMAIN = "yogamitra-live-21693.firebaseapp.com"
        FIREBASE_PROJECT_ID = "yogamitra-live-21693"
        FIREBASE_STORAGE_BUCKET = "yogamitra-live-21693.appspot.com"
        FIREBASE_MESSAGING_SENDER_ID = "509636539738"
        FIREBASE_APP_ID = "1:509636539738:web:6c6f17b5ddf24dc2116f63"
        break;
    default:
        console.log("connected to firebase dev default")
        FIREBASE_API_KEY = "AIzaSyA5TCs8861BbWd6X9tqS3ltGK834Fx0QyU"
        FIREBASE_AUTH_DOMAIN = "yogamitra-ee5f5.firebaseapp.com"
        FIREBASE_PROJECT_ID = "yogamitra-ee5f5"
        FIREBASE_STORAGE_BUCKET = "yogamitra-ee5f5.appspot.com"
        FIREBASE_MESSAGING_SENDER_ID = "926744598582"
        FIREBASE_APP_ID = "1:926744598582:web:25cab08bc66b6fa437e097"
        break;
}

console.log(API_HOST)

let API_VERSION = 'api/v1'

export {
    APP_ENV,
    API_HOST,
    API_VERSION,
    GA4_ID,
    FRONTEND_HOST,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID
}