import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../contexts/AppFirebaseContext";
import Modal from "../AppModal/Modal";
import AppPrimaryButton from "../AppPrimaryButton";
import style from "./a.module.scss";
import { useEffect, useState } from "react";
import checkIsPaidUser from "./contexts";

const AppPaidUser = ({ children }) => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [isPlanExpired, setIsPlanExpired] = useState(false);

  useEffect(() => {
    const checkPlan = async (email) => {
      const query = {
        email: email,
      };
      if (query.email) {
        const res = await checkIsPaidUser(query);
        if (res == true) {
          setIsPlanExpired(false);
        } else {
          setIsPlanExpired(true);
        }
      }
    };
    if (user) {
      checkPlan(user.email);
    }
  }, [user]);

  const ErrorCom = () => {
    return (
      <Modal isModelAlwaysOpen={true}>
        <h1>Select the plan</h1>
        <h2>Buy the plan to create class</h2>
        <div className={style.renewButton}>
          <div onClick={() => navigate("/")}>
            <AppPrimaryButton title="Select Plan" fill={true} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={style.paidModel}>
      {isPlanExpired ? <ErrorCom /> : <>{children}</>}
    </div>
  );
};

export default AppPaidUser;
