import React, { useEffect, useState } from "react";
import style from "../../a.module.scss";
import AppPrimaryButton from "../../../../components/AppPrimaryButton";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import getClassDetails, {
  changeUserClassDetail,
  deleteRequestClassFromUser,
  getUserDetails,
} from "../../contexts";
import * as XLSX from "xlsx";

const RequestedUserComp = (props) => {
  const { id } = useParams();
  const { classType } = useParams();
  const navigate = useNavigate();
  const [requestedUser, setRequestedUser] = useState([]);
  const [acceptedUser, setAcceptedUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const classDetails = props.classDetails;

  const getUser = async (classDetails) => {
    const requested_users = classDetails.requested_user;
    const userDetail = [];
    for (let i = 0; i < requested_users.length; i++) {
      const res = await getUserDetails(requested_users[i]);
      userDetail.push(res[0]);
    }
    setRequestedUser(userDetail);
    setIsLoading(false);
    setIsSaveLoading(false);
  };
  const fetchClassDetails = async (id) => {
    const res = await getClassDetails(id, classType);
    getUser(res[0]);
  };
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchClassDetails(id);
    }
  }, []);

  const handleUser = (newNumber) => {
    if (acceptedUser.includes(newNumber)) {
      setAcceptedUser(acceptedUser.filter((number) => number !== newNumber));
    } else {
      setAcceptedUser([...acceptedUser, newNumber]);
    }
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    const userIds = [];
    for (let i = 0; i < acceptedUser.length; i++) {
      const user = requestedUser[acceptedUser[i]];
      userIds.push(user._id);
      // const
    }
    const query = {
      id: id,
      registered_user: userIds,
      delete_requested_user: userIds,
    };
    const class_res = await changeUserClassDetail(query, classType);
    const user_res = await deleteRequestClassFromUser(classType, id, userIds);
    setAcceptedUser([]);
    fetchClassDetails(id);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      const newArray = Array.from(
        { length: requestedUser.length },
        (_, index) => index
      );
      setAcceptedUser(newArray);
    } else {
      setAcceptedUser([]);
    }
  };

  const convertJsonToExcel = (json) => {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    return url;
  };

  const downloadExcel = () => {
    let sheetData = [];

    for (let i = 0; i < requestedUser.length; i++) {
      const user = requestedUser[i];
      const userDetails = {
        Name: user.name,
        "Phone Number": user.phone_number,
        Email: user.email,
        "Is user verified": user.is_email_verified ? "Yes" : "No",
        Username: user.username,
      };
      sheetData.push(userDetails);
    }
    const url = convertJsonToExcel(sheetData);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${classDetails.name}-requested-${classType}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return <>Loading ..... </>;
  }
  if (requestedUser == undefined || requestedUser.length == 0) {
    return <><p style={{margin: "2em 0"}}>No requested user</p></>;
  }

  return (
    <div>
      <div className={style.checkboxButton}>
        <div className={style.checkboxes}>
          <div>
            <input
              type="checkbox"
              id="markAllPaid"
              name="markAllPaid"
              onChange={handleCheckboxChange}
            ></input>
            <label htmlFor="markAllPaid"> Accept all students</label>
          </div>
        </div>
        <div className={style.checkboxSaveButton} onClick={() => handleSave()}>
          <AppPrimaryButton title="Save" />
        </div>
      </div>
      {isSaveLoading ? (
        <h1>Saving ... </h1>
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Student name</th>
                <th>Accept Student</th>
              </tr>
            </thead>
            <tbody>
              {requestedUser.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/user/${data.username}`)}
                    >
                      {data?.name}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        // checked={data.checked}
                        checked={acceptedUser.includes(index)}
                        onChange={() => handleUser(index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{ margin: "2em auto", padding: "0 2em", maxWidth: "20em" }}
            className={style.checkboxSaveButton}
            onClick={downloadExcel}
          >
            <AppPrimaryButton title="Download Student Data" fill={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestedUserComp;
