
function isDateLessThanCurrentDate(dateString) {
    if(dateString==undefined || dateString==null) return false;
    const currentDate = new Date();
    const givenDate = new Date(dateString);
    return givenDate < currentDate;
}

function isDateGreaterThanCurrentDate(dateString) {
    if(dateString==undefined || dateString==null) return false;
    const currentDate = new Date();
    const givenDate = new Date(dateString);
    return givenDate >= currentDate;
}

export {isDateLessThanCurrentDate, isDateGreaterThanCurrentDate}