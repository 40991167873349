import { useNavigate } from "react-router-dom"
import { UserAuth } from "../../contexts/AppFirebaseContext"
import Modal from "../AppModal/Modal"
import AppPrimaryButton from "../AppPrimaryButton"
import style from "./a.module.scss"
import { useEffect, useState } from "react"

const AppUserLogin = ({children}) => {
    const navigate = useNavigate()
    const {user} = UserAuth()
    const [isUserLogin, setIsUserLogin] = useState(false)

    useEffect(()=>{
        // if(user?.email){
            setIsUserLogin(true)
        // }
    },[])

    const ErrorCom = () => {
        return(
            <Modal isModelAlwaysOpen={true}>
                <h1>Please Signin to continue</h1>
                <div className={style.renewButton}>
                    <div onClick={()=>navigate('/signin')}>
                        <AppPrimaryButton title="Signin" fill={true}/>
                    </div>
                </div>
            </Modal>
        )
    }


    return (
        <div className={style.signinModel}>  
            {isUserLogin ? <>{children}</>:<ErrorCom /> }
        </div>
    )
}

export default AppUserLogin