import React, { useEffect, useState } from 'react'

import style from './a.module.scss'

import { TimePicker } from 'react-rainbow-components';

const AppTimePicker = ({ value, onChange }) => {
    const initialState = {
		date: "",
		locale: { name: "en-US", label: "English (US)" },
	};

	const containerStyles = {
		maxWidth: 200,
	};

	const [TimeValue, setTimeValue] = useState();

	useEffect(() => {
		if (value) {
		setTimeValue(value);
		} else {
		setTimeValue(initialState.date);
		}
	}, [value]);

    return (
        <div className={style.appTimePicker}>
            <TimePicker
            id="time-picker-1"
            value={TimeValue}
            onChange={onChange}
            style={containerStyles}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
            />
        </div>
  )
}

export default AppTimePicker