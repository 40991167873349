import { memo, useCallback, useEffect, useState } from "react";


import { Field } from "../../../../../components/AppField";
import AppPrimaryButton from "../../../../../components/AppPrimaryButton";


export const Form = memo((props) => (
  <div >
    <form onSubmit={props.onSubmit} noValidate>
      <h1 style={{ margin: "0 0 1em 0" }}>Fill these details</h1>
      <div>
        <Field
          labelText="Name"
          fieldType="text"
          fieldName="name"
          placeholder="Your first name"
          fieldValue={props.values.name}
          hasError={props.errors.name}
          onFieldChange={props.onFieldChange}
        />
        <Field
          labelText="Username"
          fieldType="text"
          fieldName="username"
          placeholder="Username"
          fieldValue={props.values.username}
          hasError={props.errors.username}
          onFieldChange={props.onFieldChange}
        />
        {/* {props.errors.name && <div>{props.errors.name}</div>} */}
        <Field
          labelText="Phone Number"
          fieldType="number"
          fieldName="phoneNumber"
          placeholder="Your personal phone number"
          fieldValue={props.values.phoneNumber}
          hasError={props.errors.phoneNumber}
          onFieldChange={props.onFieldChange}
        />
      </div>
      <button style={{ marginTop: "2em" , width:"100%"}} type="submit">
        <AppPrimaryButton title="Continue" fill={true} />
      </button>
    </form>
  </div>
));
