import { memo, useEffect, useState } from "react";
import style from "../a.module.scss";

import Datepicker from "react-tailwindcss-datepicker";
import AppPrimaryButton from "../../../components/AppPrimaryButton";
import { CheckboxToggle } from "react-rainbow-components";

import { Field } from "../../../components/AppField";
import { useNavigate } from "react-router";

const RadioButton = (props) => {
  const [value, setValue] = useState("Beginner");

  const onOptionChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    props.onFieldChange("level", value);
  }, [value]);
  useEffect(() => {
    if (props.fieldValue) {
      setValue(props.fieldValue);
    }
  }, [props]);
  return (
    <div className={style.radioButtons}>
      <div>
        <input
          type="radio"
          name="value"
          value="Beginner"
          id="Beginner"
          checked={value === "Beginner"}
          onChange={onOptionChange}
        />
        <label htmlFor="Beginner">Beginner</label>
      </div>
      <div>
        <input
          type="radio"
          name="valie"
          value="Intermediate"
          id="Intermediate"
          checked={value === "Intermediate"}
          onChange={onOptionChange}
        />
        <label htmlFor="Intermediate">Intermediate</label>
      </div>
      <div>
        <input
          type="radio"
          name="value"
          value="Advance"
          id="large"
          checked={value == "Advance"}
          onChange={onOptionChange}
        />
        <label htmlFor="Advance">Advance</label>
      </div>
    </div>
  );
};

const ToggleButton = (props) => {
  const [value, setValue] = useState(true);

  const onOptionChange = (e) => {
    setValue(!value);
  };
  useEffect(() => {
    props.onFieldChange("private", value);
  }, [value]);
  return (
    <div>
      <CheckboxToggle
        id="checkbox-toggle-component-1"
        labelAlignment="left"
        value={value}
        onChange={onOptionChange}
      />
    </div>
  );
};

const DatePicker = (props) => {
  const [dateFormat, setDateFormat] = useState("");
  const [value, setValue] = useState(dateFormat);
  useEffect(() => {
    setDateFormat({ startDate: props.fieldValue, endDate: props.fieldValue });
  }, [props.fieldValue]);
  const onOptionChange = (e) => {
    setValue(e);
  };
  useEffect(() => {
    if (props.fieldName == "startDate") {
      props.onFieldChange(props.fieldName, value.startDate);
    } else {
      props.onFieldChange(props.fieldName, value.endDate);
    }
  }, [value]);

  return (
    <Datepicker
      asSingle={true}
      value={dateFormat}
      onChange={onOptionChange}
      showFooter={true}
      useRange={false}
      popoverDirection="down"
    />
  );
};

const SeriesForm = (props) => {
  console.log(props);
  const navigate = useNavigate();
  return (
    <div className={style.createClass}>
      <h1 style={{fontSize:"2em", fontWeight:"600"}}>Edit Drop-in</h1>
      <form onSubmit={props.onSubmit} noValidate>
        <div className={style.titleDiv}>
          <Field
            labelText="Title"
            fieldType="text"
            fieldName="title"
            placeholder="Title of the drop-in will be here"
            fieldValue={props.values.title}
            hasError={props.errors.title}
            onFieldChange={props.onFieldChange}
          />
        </div>
        <div className={style.startEndDatePickersDiv}>
          <div className={style.seriesDatePickerDiv}>
            <p>Start date</p>
            <DatePicker
              onFieldChange={props.onFieldChange}
              fieldValue={props.values.startDate}
              fieldName="startDate"
            />
          </div>
          <div className={style.seriesDatePickerDiv}>
            <p>End date</p>
            <DatePicker
              onFieldChange={props.onFieldChange}
              fieldValue={props.values.endDate}
              fieldName="endDate"
            />
          </div>
        </div>
        <div className={style.timePickerSection}>
          <div>
            <Field
              labelText="Start Time"
              fieldName="startTime"
              placeholder="Title of the drop-in will be here"
              fieldValue={props.values.startTime}
              hasError={props.errors.startTime}
              onFieldChange={props.onFieldChange}
              typeOfField={"timePicker"}
            />
          </div>
          <div>
            <Field
              labelText="End Time"
              fieldName="endTime"
              placeholder="Title of the drop-in will be here"
              fieldValue={props.values.endTime}
              hasError={props.errors.endTime}
              onFieldChange={props.onFieldChange}
              typeOfField={"timePicker"}
            />
          </div>
        </div>
        <div className={style.descriptionInput}>
          <Field
            labelText="Description"
            fieldName="description"
            placeholder="Title of the drop-in will be here"
            fieldValue={props.values.description}
            hasError={props.errors.description}
            onFieldChange={props.onFieldChange}
            typeOfField={"textArea"}
          />
        </div>
        <div className={style.radio}>
          <p>Levels</p>
          <div>
            <RadioButton
              onFieldChange={props.onFieldChange}
              fieldValue={props.values.level}
            />
          </div>
        </div>
        <div className={style.fullInput}>
          <Field
            labelText="Location"
            fieldType="text"
            fieldName="location"
            placeholder="Enter full address here"
            fieldValue={props.values.location}
            hasError={props.errors.location}
            onFieldChange={props.onFieldChange}
          />
        </div>
        <div className={style.twoHalfDivs}>
          <div>
            <Field
              labelText="No. of mats"
              fieldType="number"
              fieldName="mats"
              placeholder="eg. 3/10"
              fieldValue={props.values.mats}
              hasError={props.errors.mats}
              onFieldChange={props.onFieldChange}
            />
          </div>
          <div>
            <Field
              labelText="Price"
              fieldType="text"
              fieldName="price"
              placeholder="eg. 10$"
              fieldValue={props.values.price}
              hasError={props.errors.price}
              onFieldChange={props.onFieldChange}
            />
          </div>
        </div>

        <div className={style.toggleDiv}>
          <p>Make private Series </p>
          <ToggleButton
            onFieldChange={props.onFieldChange}
            fieldValue={props.values.endTime}
          />
        </div>

        <div className={style.twoHalfDivs}>
          <div onClick={() => navigate(-1)}>
            <AppPrimaryButton title="Back" />
          </div>
          <div>
            <button
              type="submit"
              className={style.createButton}
              style={{
                color: "#942bf1",
                border: "2px solid white",
                color: "white",
              }}
            >
              <AppPrimaryButton
                title="Save changes"
                fill={true}
              ></AppPrimaryButton>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default memo(SeriesForm);
