import React from 'react'

import style from "./a.module.scss";


const PrivacyPolicy = () => {
  return (
    <div className={style.overallPage}>
    <h3>Privacy Policy</h3>

    This Privacy Statement explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including your personal information, by CLASSMITRA LLP sole proprietorship (“CLASSMITRA LLP”)
    
    <h4>Changes To This Privacy Policy</h4>
    
    We may in future update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    
    <h4>Contacting Us</h4>
    
    If you have general questions about your account or how to contact Customer Service for assistance, please email us at admin@classmitra.in . For questions specifically about this Privacy Statement, or our use of your personal information, cookies or similar technologies, please contact us by email at admin@classmitra.in
    
    You can also write to us at the following address:
    
    CLASSMITRA LLP,
    Office 52, East Street Gallaria, East street, Camp Pune -411001
    
    <h4>Attention: Privacy.</h4>
    
    Please note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request.
    
    <h4>Collection of Information</h4>
    
    We receive and store information about you such as: Information you provide to us: We collect information you provide to us which includes:
    
     
    <ul>
    <li>Your name, email address, address or postal code, payment method and telephone number.
     We collect this information in a number of ways, including when you enter it while using our service, 
     interact with our Customer Service, sign up for our newsletter or participate in surveys or marketing promotions;</li>
     
     <li>Information when you choose to provide reviews or ratings, taste preferences, account settings,
      set preferences in Your Account, or otherwise provide information to us through our service or elsewhere.</li>
      <li>Information we collect automatically: We collect information about you and your use of our service, your interactions with us and our advertising, as well as information regarding your computer or other device used to access our service
       (such as gaming systems, smart TVs, mobile devices, and set top boxes). This information includes
      </li>
        <ol>
            <li>your activity on the CLASSMITRA LLP service, such as title selections, watch history and search queries;</li>
            <li>details of your interactions with Customer Service, such as the date, time and reason for contacting us, 
            transcripts of any chat conversations, and if you call us, your phone number and call recordings;</li>
            <li>device IDs or other unique identifiers, device and software characteristics (such as type and configuration), connection information, statistics on page views, referral URLs, IP address
             (which may tell us your general location), browser and standard web server log information;</li>
             <li>device IDs or other unique identifiers, device and software characteristics (such as type and configuration), connection information, statistics on page views, referral URLs, IP address
              (which may tell us your general location), browser and standard web server log information;</li>

        </ol>

        <li>Information from other sources: We might supplement the information described above with information we obtain from other sources, including from both online and offline data providers, and social media . 
        Such supplemental information could include demographic data, interest based data, and Internet browsing behavior.</li>
    </ul>
    

    <h4>Use of Information</h4>

    <p>We use information to provide, analyze, administer, enhance and personalize our services and marketing efforts, to process your registration, your orders and your payments, and to communicate with you on these and other topics. For example, we use information to:
    </p>

    <ul>
        <li>determine your general geographic location, provide localized content, provide you with customized and personalized viewing recommendations for movies and TV shows we think will be enjoyable,
         determine your Internet service provider, and help us quickly and efficiently respond to inquiries and requests;</li>
         <li>prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and enforcing our terms
          (such as determining free trial eligibility);</li>
          <li>analyze and understand our audience, improve our service (including our user interface experiences) 
          and optimize content selection, recommendation algorithms and delivery;</li>
          <li>communicate with you concerning our service (for example by email, push notifications text messaging,
             and online messaging channels), so that we can send you news about CLASSMITRA LLP, 
             details about new features and content available on CLASSMITRA LLP, and special offers,
              promotional announcements and consumer surveys, and to assist you with operational requests such as password reset requests.
           Please see the “Your Choices” section of this Privacy Statement to learn how to set or change your communications preferences.</li>
    </ul>

    <h4>Disclosure of Information</h4>

    <p>We disclose your information for certain purposes and to third parties, as described below:</p>

    <ul>
            <li><h4>The CLASSMITRA LLP:</h4> We share your information for: data processing and storage; providing you with access to our services; providing customer support; making decisions about service improvements, content development;
             and for other purposes described in the Use of Information section of this Privacy Statement.
            </li>
            <li><h4>Service Providers:</h4> We use other companies, agents or contractors (“Service Providers”) to perform services on our behalf or to assist us with the provision of services to you. For example, we engage Service Providers to provide marketing, advertising, communications, infrastructure and IT services, to personalize and optimize our service, to process credit card transactions or other payment methods, to provide customer service, to collect debts, to analyze and enhance data (including data about users interactions with our service), and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to your personal or other information. 
            We do not authorize them to use or disclose your personal information except in connection with providing their services.</li>
            <li><h4>Promotional offers:</h4>We do not sell or give your personal information to any other companies or service providers outside of those mentioned in this Privacy policy. The only use of your information for promotional offers is through audiences created in Facebook,
             Mailchimp and Instagram, all of which adhere to the GDPR and Data protection policies.</li>
             <li><h4>Protection of CLASSMITRA LLP and others:</h4>CLASSMITRA LLP and its Service Providers may disclose and otherwise use your personal and other information where we or they reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including payment fraud), security or technical issues, or (d) protect against harm to the rights, property or safety of CLASSMITRA LLP,
              its users or the public, as required or permitted by law.</li>
              <li><h4>Business transfers:</h4> In connection with any reorganization, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to respect your personal information in a manner that is consistent with our Privacy Statement. Whenever in the course of sharing information we transfer personal information to countries outside of the Indian subcontinent region and other regions with comprehensive data protection laws, we will ensure that the information is transferred in accordance with this Privacy Statement and as permitted by the applicable laws on data protection,
               including the new GDPR regulations.</li>
            </ul>

            <p>You may also choose to disclose your information in the following ways:
            </p>

            <ul>
            <li>While using the CLASSMITRA LLP service you will have opportunities to post 
            reviews or other information publicly, and third parties could use the information you disclose;</li>
            <li>Certain portions of our service may contain a tool which gives you the option to share information by email, text message and social or other 
            sharing applications, using the clients and applications on your smart device;</li>
            <li>Social plugins (including those offered by Facebook, Twitter, Pinterest, and Google 
                (Ads & Analytics) allow you to share information on those platforms.</li>
            
            </ul>

            <p>Social plugins and social applications are operated by the social network themselves, and are subject to their terms of use and privacy policies.</p>
    <h4>Security Of Data</h4>
    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            
    <h4>Links To Other Sites
    </h4>
    Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. CLASSMITRA LLP has no control over and assumes no responsibility for the content, privacy policies or practices of any third party sites or services.

            
    <h4>Access to Your Account and Profiles</h4>
    In order to provide you with ease of access to your account and to help administer the CLASSMITRA LLP service, we use technology that enables us to recognize you as the account holder and provide you with direct access to your account without requiring you to retype any password or other user identification when you revisit the CLASSMITRA LLP service. You can choose not to take advantage of this feature on your computer by unchecking the box “Remember me on this device” when you log in to the website. To remove access to your CLASSMITRA LLP account from your devices: (a) visit your “Profile” icon, choose “Sign out of all devices,” and follow the instructions to deactivate your devices (note, deactivation may not occur immediately) or (b) delete your CLASSMITRA LLP settings from your device (steps vary by device and the option is not available on all devices). Where possible, users of public or shared devices should log out at the completion of each visit. If you sell or return a computer or CLASSMITRA LLP ready device, you should log out and deactivate the device before doing so. If you do not maintain the security of your password or device, or fail to log out or deactivate your device, subsequent users may be able to access your account, including your personal information.

    If you share or otherwise allow others to have access to your account, they will be able to see your information (including in some cases personal information) such as your watch history, ratings, reviews and account information (including your email address or other information in your “Profile”).

                <h4>Your Choices
                </h4>

                If you no longer want to receive certain communications from us via email, simply access the email settings page in the “Profile” section of our website and uncheck those items to unsubscribe. Alternatively, click the “unsubscribe” link in the email. Please note that you cannot unsubscribe from certain email correspondence from us, such as messages relating to your account transactions.

    You can opt in to receive mobile push notifications from CLASSMITRA LLP. 
    If you subsequently decide you no longer wish to receive these notifications, 
    you can use your mobile device’s settings functionality to turn them off. 
    To exercise choices regarding cookies set through our website, as well as other types of online tracking and Internet advertising, 
    see the Cookies and Internet Advertising section (below).

    <h4>Your Information and Rights
    </h4>

    You can request access to your personal information, or correct or update out-of-date or inaccurate personal information we hold about you.

You can most easily do this by visiting the “Profile” portion of our website, where you have the ability to access and update a broad range of information about your account, including your contact information, your CLASSMITRA LLP payment information, and various related information about your account (such as the content you have viewed and rated, and your reviews). You must be signed in to access your “Profile”. You may also request that we delete personal information that we hold about you.

To make requests, or if you have any other questions regarding our privacy practices, please contact us at admin@classmitra.in .
 Please also see the Your Choices section of this Privacy Statement for additional choices regarding your information.
  We may reject requests that are unreasonable or not required by law, including those that would be extremely impractical, could require disproportionate technical effort, or could expose us to operational risks such as free trial fraud. We may retain information as required or permitted by applicable laws and regulations, 
including to honor your choices, for our billing or records purposes and to fulfill the purposes described in this Privacy Statement.

                <h4>Security</h4>
                We use reasonable administrative, logical, physical and managerial measures to safeguard your personal information against loss, theft and unauthorized access, use and modification. Unfortunately, no measures can be guaranteed to provide 100% security. Accordingly,
                 we cannot guarantee the security of your information.
                 <h4>Other Websites, Platforms and Applications
                 </h4>

                 The CLASSMITRA LLP service may be provided through and/or utilize features (such as voice controls) operated by
                  third party platforms, or contain links to sites operated by third parties whose policies regarding the handling 
                  of information may differ from ours. For example, you may be able to access the CLASSMITRA LLP service through 
                  platforms such as gaming systems, smart TVs, mobile devices, set top boxes and a number of other Internet connected 
                  devices. These websites and platforms have separate and independent privacy or data policies, privacy statements, notices and terms of use, which we recommend you read carefully. In addition, you may encounter third party applications that interact with the CLASSMITRA LLP service.


                  <h4>Children</h4>
                  You must be 18 years of age or older to subscribe to the CLASSMITRA LLP service. In certain jurisdictions, the age of majority may be older than 18, in which case, you must satisfy that age in order to become a member. While individuals under the age of 18 may utilize the service, they may do so only with the involvement, supervision, and approval of a parent or legal guardian.

                <h4>Changes to This Privacy Statement
                </h4>
                We will update this Privacy Statement from time to time in response to changing legal, regulatory or operational requirements. We will provide notice of any such changes (including when they will take effect) in accordance with law. Your continued use of the CLASSMITRA LLP service after any such updates take effect will constitute acceptance of those changes. If you do not accept any updates to this Privacy Statement, you may cancel your use of the CLASSMITRA LLP service. To see when this Privacy Statement was last updated, please see the “Last Updated” section below.

                <h4>Cookies and Internet Advertising
                </h4>

                We and our Service Providers and we use cookies and other technologies (such as web beacons) for various reasons. For example, we use cookies and other technologies to make it easy to access our services by remembering you when you return, to provide and analyze our services, to learn more about our users and their likely interests, and to deliver and tailor marketing or advertising. We want you to be informed about our use of these technologies, so this notice explains the types of technologies we use, what they do and your choices regarding their use.
                <ol>
                
                <li>What are cookies? Cookies are small data files that are commonly stored on your
                 device when you browse and use websites and online services. They are widely used to make websites work, 
                 or to work more efficiently, as well as to provide reporting information and assist with service or advertising
                  personalization. Cookies are not the only types of technology that enable this functionality; we also use other, 
                  similar types of technologies. See below for more information and examples.</li>
                <li>Why does CLASSMITRA LLP use cookies? We and our Service Providers may use the following types of cookies:
                    </li>

                <ul>
                <li><h4>Essential cookies:</h4>These cookies are strictly necessary to provide our website or online service. For example, we or our Service Providers may use these cookies to authenticate and identify our members when they use our websites and applications so we can provide our service to them.
                 They also help us to enforce our Terms of Use, prevent fraud and maintain the security of our service.</li>
                 <li><h4>Performance and functionality cookies: </h4> These cookies are not essential, but help us to personalize and enhance your online experience with CLASSMITRA LLP. For example, they help us to remember your preferences and prevent you from needing to re-enter information you previously provided (for example, during member sign up). We also use these cookies to collect information (such as popular pages, conversion rates, viewing patterns, click-through and other information) about our visitors’ use of the CLASSMITRA LLP service so that we can enhance and personalize our website and service and conduct market research.
                  Deletion of these types of cookies will result in limited functionality of our service.</li>
                 <li><h4>Advertising cookies: </h4>These cookies use information about your visit to this and other websites, such as the pages you visit, your use of our service or your response to ads and emails, to deliver ads that are more relevant to you. These types of ads are called “Interest-Based Advertising.” Many of the advertising cookies associated with our service belong to our Service Providers.</li>
                 </ul>
                 <li>How can I exercise choice regarding cookies and other types of online tracking? For more information about cookies set through our website, as well as other types of online tracking (including the collection of information by third parties about your online activities over time and across third-party Web sites or online services for online interest based advertising), and to exercise choices regarding them,
                 go to https://en.wikipedia.org/wiki/HTTP_cookie.
                 At this time, we do not respond to Web browser “do not track” signals.</li>
                 <li>How does CLASSMITRA LLP use Web Beacons and other Technologies? Web beacons (also known as clear gifs or pixel tags) often work in conjunction with cookies. We and our Service Providers may use them for similar purposes as cookies, such as to understand and enhance the use of our service, improve site performance, monitor visitor traffic and actions on our site, and understand interactions with our marketing (including email and online ads on third party sites). Because web beacons often work in conjunction with cookies, in many cases,
                 declining cookies will impair the effectiveness of web beacons.</li>
                 </ol>
                 We use other technologies that are similar to cookies, such as browser storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). Like cookies, some of these technologies may store small amounts of data on your device. We may use these and various other technologies for similar purposes as cookies, such as to enforce our terms, prevent fraud, and analyze the use of our service. There are a number of ways to exercise choice regarding these technologies. For example, many popular browsers provide the ability to clear browser storage, commonly in the settings or preferences area; see your browser’s help function or support area to learn more.
                 
                 Yogamitra use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
                 
                 <h4>PRIVACY POLICY FOR CLASSMITRA LLP APPS</h4>This CLASSMITRA LLP App Privacy Policy (“Privacy Policy”) describes how we collect and use your information through digital means. By accessing or using this mobile application you consent to our Privacy Policy. The CLASSMITRA LLP App has been built by CLASSMITRA LLP and will be known as “CLASSMITRA LLP App” in this Privacy Policy. CLASSMITRA LLP App may modify this Privacy Policy at any time effective upon its posting. Your continued use of our products and services constitutes your acceptance to this Privacy Policy and any updates. This Privacy Policy is incorporated into, and is subject to, the Terms of Use.
                 
                 <h4>WHAT INFORMATION DO WE COLLECT FROM YOU?
                 </h4>Information we may collect includes, but is not limited to, your telephone number, Device identifier, GPS location, cookie information, system type, information about your purchases and other information about your interactions with us. We may automatically collect information when you interact with the CLASSMITRA LLP App mobile application.

                <h4>Childrens Privacy
                </h4>
                We do not knowingly solicit or collect personal information from children under 13.

                <h4>INFORMATION MAY BE USED TO OPERATE, IMPROVE AND ANALYZE USER EXPERIENCE
                </h4>
                CLASSMITRA LLP App may use the information we collect to analyze user behavior and trends, send you administrative messages, send you in-app messages and other information about CLASSMITRA LLP App, and communicate with you.

                <h4>INFORMATION MAY BE USED TO PROVIDE AND CONDUCT MARKETING AND PROMOTIONS
                </h4>CLASSMITRA LLP App may use the information we collect to communicate with you about our products, services and events, promotions, surveys or other app features. Information may also be used to display relevant marketing material to you.

                <h4>SHARING INFORMATION
                </h4>CLASSMITRA LLP App may provide your information to and their respective affiliates. We may also share your information to administer new app features (Ex: Fitbit API integration).


                <h4>BY LAW OR TO PROTECT RIGHTS
                </h4>We may share information to prevent illegal uses of the CLASSMITRA LLP App or violations of the Terms of Use, or to defend ourselves against third-party claims. We may disclose information upon governmental request, in response to a court order, when required by law, to enforce our policies, or to protect our or others rights, property or safety.


                <h4>TRANSFER OF OWNERSHIP OR ASSETS
                </h4>Your information may be transferred to a third party as a part of our business assets in a sale of a part or all of the CLASSMITRA LLP App partnership owners.


                <h4>SOCIAL MEDIA INFORMATION SHARING
                </h4>   If you choose to share your activity on other platforms, such as Facebook or other social media platforms, please read the privacy policies of those platforms. Any information about CLASSMITRA LLP App published on those platforms will no longer be governed by CLASSMITRA LLP App’s Privacy Policy.


                <h4>CLASSMITRA LLP App ACCESS TO PUBLIC INFORMATION
                </h4>Information that is publicly shared may be used by CLASSMITRA LLP App for marketing or promotional purposes.


                <h4>PROTECTING INFORMATION
                </h4>We use a variety of security measures, including encryption and authentication tools, to help protect your information. We use secure servers for your profile and subscription information to the app.


                <h4>NO GUARANTEE
                </h4>Like other companies, CLASSMITRA LLP App cannot guarantee 100% the security or confidentiality of the information you provide to us. We have put measures in place to do our best so that your information security is not breached.


                <h4>MODIFYING OR DELETING YOUR INFORMATION
                </h4>You can modify or delete your profile information through your CLASSMITRA LLP App account. CLASSMITRA LLP App may keep information provided and consented upon in our backup files and archives.


                <h4>OPT-OUT/ UNSUBSCRIBE
                </h4>You may unsubscribe to email promotions and notifications by using the “Unsubscribe” link in the respective email or changing your settings. CLASSMITRA LLP App may still send you in-app notifications. You have the ability to toggle off notifications in your profile of the app.


                <h4>INTERNATIONAL USERS
                </h4>Regardless of where you live, you consent to have your personal data transferred, processed and stored in both the United States and India, and allow CLASSMITRA LLP App to use and collect your personal information in accordance with this Privacy Policy.


                <h4>TERMS OF USE
                </h4>Your use of the CLASSMITRA LLP App is subject to this Privacy Policy as well as our Terms of Use.

                For questions or concerns, please contact admin@classmitra.in
                 
    </p>
    </div>
  )
}

export default PrivacyPolicy;
