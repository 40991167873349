import { memo, useCallback, useState } from "react";
import update from "immutability-helper";
import * as yup from "yup";

import Form from "./components/SignUpForm";
import checkValuesInUserDb from "../../contexts";
import { UserAuth } from "../../../../contexts/AppFirebaseContext";

const SignUp = memo(() => {
  const { emailSignUp } = UserAuth();

  const CheckEmail = async (email) => {
    let query = {
      email: email,
    };
    const res = await checkValuesInUserDb(query);
    return res;
  };

  // const saveDetails = async (details) => {
  //     let formData = {
  //         'name': details.name,
  //         'username': details.username,
  //         'phone_number': details.phoneNumber,
  //     }
  //     const res = await saveDetials(formData)
  //     if(res){
  //         navigate(`/user/${res.username}`)
  //     }
  // }

  const _handleSignIn = async (email, password) => {
    try {
      await emailSignUp(email, password);
    } catch (error) {
      console.log(error);
    }
  };
  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("*Invalid email address")
      .test("email validation", "Email already exists", function (value) {
        return CheckEmail(value) || "Email already exists";
      })
      .required("*Email is required"),
    password: yup
      .string()
      .required("*Password is required")
      .min(8, "*Password must be at least 8 characters")
      .matches(/[A-Z]/, "*Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "*Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "*Password must contain at least one numeric value")
      .matches(
        /[^A-Za-z0-9]/,
        "*Password must contain at least one special character"
      ),
    confirmPassword: yup
      .string()
      .required("*Confirm password is required")
      .oneOf([yup.ref("password"), null], "*Passwords must match"),
  });

  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const onFieldChange = useCallback((fieldName, value) => {
    setValues((prevValues) =>
      update(prevValues, {
        [fieldName]: {
          $set: value,
        },
      })
    );
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const isFormValid = await formSchema.isValid(values, {
        abortEarly: false,
      });
      if (isFormValid) {
        _handleSignIn(values.email, values.password);
      } else {
        formSchema.validate(values, { abortEarly: false }).catch((err) => {
          const error = {};
          err.inner.forEach((err) => {
            error[err.path] = err.message;
          });
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: error,
            })
          );
        });
      }
    },
    [values]
  );

  return (
    <Form
      values={values}
      errors={errors}
      onFieldChange={onFieldChange}
      onSubmit={onSubmit}
      formSchema={formSchema}
    />
  );
});

export default SignUp;
