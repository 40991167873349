import { useContext, createContext, useEffect, useState } from "react";
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithRedirect, signOut, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth } from "../configs/firebase";
import { FRONTEND_HOST } from "../configs/EnvVariables";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserDetail } from "./AppUserDetailContext";

const AuthContext = createContext(0)


const extract_error = (error_message) => {
    const inputString = error_message;
    const regex = /\((.*?)\)/;
    const match = inputString.match(regex);

    if (match && match.length > 1) {
        const extractedText = match[1];
        return extractedText;
    } else {
        return "Error please try again"
    }
}

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [initialErrorMessage, setInitialErrorMessage] = useState("")
    const [loginLoading, setLoginLoading] = useState(false);
    const [isResetPasswordLinkSent, setIsResetPasswordLinkSent] = useState(false)
    const { FetchUserDetails } = UserDetail()

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
            .then(() => {
                window.location.href = '/'
                toast.success("Successfully signed in")
            })
            .catch((error) => {
                console.error("Error sending email verification:", error);
                toast.error(error.message)
            });
    }

    const googleSignUp = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
            .then(() => {
                // localStorage.removeItem("appUser");
                window.location.href = '/signup/details'
                toast.success("Sign Up Success")
            })
            .catch((error) => {
                let error_message = extract_error(error.message)
                toast.error(error_message);
            });
    }

    const facebookSignIn = () => {
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth, provider);
    }

    const emailSignUp = (email, password) => {
        setLoginLoading(true)
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                var actionCodeSettings = {
                    url: `${FRONTEND_HOST}/signup/details`,
                    handleCodeInApp: true
                };
                sendEmailVerification(user, actionCodeSettings)
                    .then(() => {
                        toast.success("Email verification sent");
                        window.location.href = '/signup/verifyaccount'
                    })
                    .catch((error) => {
                        let error_message = extract_error(error.message)
                        toast.error(error_message);
                    });
                setLoginLoading(false);
            })
            .catch((error) => {
                let error_message = extract_error(error.message)
                toast.error(error_message);
            });
    }

    const emailSignIn = async (email, password) => {
        setLoginLoading(true)
        let res;
        const ress = await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                toast.success("Successfully signed in");
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            })
            .catch((error) => {
                let error_message = extract_error(error.message)
                toast.error(error_message);

            });
        return res
    }

    const forgotPasword = (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success("Reset Password link successfully sent");
                setTimeout(() => {
                    window.location.href = '/signin';
                }, 5000);
            })
            .catch((error) => {
                let error_message = extract_error(error.message)
                toast.error(error_message);
            });
    }

    const emailVerification = () => {
        sendEmailVerification(user)
            .then(() => {
                toast.success("Email verification sent");
            })
            .catch((error) => {
                let error_message = extract_error(error.message)
                toast.error(error_message);
            });
    }

    const updateEmailAuth = () => {
        updateProfile(user, {
            displayName: "aryan",
            emailVerified: "true"
        })
            .then(() => {
                console.log("Profile updated!")
            }).catch((error) => {
                console.log("Error updating profile")
            });
    }

    const logOut = () => {
        signOut(auth)
        toast.success("Profile logged out")
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser)
                const query = {
                    "email": currentUser.email
                }
                FetchUserDetails(query)
            }
        })
        return () => {
            unsubscribe();
        }
    }, [user])


    useEffect(() => {
        const extractErrorMessage = () => {
            if (initialErrorMessage) {
                const errorString = initialErrorMessage;
                const regex = /\(auth\/(.+)\)/;
                const match = errorString.match(regex);
                const userNotFound = match[1];
                setErrorMessage(userNotFound)
            }
        }
        extractErrorMessage()
    }, [initialErrorMessage])

    return (
        <AuthContext.Provider value={{ googleSignIn, googleSignUp, updateEmailAuth, emailVerification, facebookSignIn, emailSignIn, emailSignUp, logOut, user, forgotPasword, errorMessage, loginLoading, setErrorMessage, isResetPasswordLinkSent }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}
