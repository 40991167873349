import React, { useEffect, useState } from "react";
import style from "../a.module.scss";
import AppPrimaryButton from "../../../components/AppPrimaryButton";
import { changeUserClassDayDetail } from "../context";
import { useNavigate } from "react-router-dom";

const DropdownTableComponent = (props) => {
  const { registeredUserDetails, dayDetails, fetchDayDetails } = props;
  const navigate = useNavigate()
  const [classAttendedUser, setClassAttendedUser] = useState([]);
  const [attendedUser, setAttendedUser] = useState([]);

  const handleAttendUser = (userID) => {
    if (attendedUser.includes(userID)) {
      setAttendedUser(attendedUser.filter((number) => number !== userID));
    } else {
      setAttendedUser([...attendedUser, userID]);
    }
  };
  const handleAttendedCheckboxChange = (event) => {
    if (event.target.checked) {
      let newArray = [];
      for (let i = 0; i < registeredUserDetails.length; i++) {
        newArray.push(registeredUserDetails[i]["_id"]);
      }
      setAttendedUser(newArray);
    } else {
      setAttendedUser([]);
    }
  };

  const handleSave = async () => {
    let newAttendUser = [];
    let deleteAttendUser = [];
    for (let i = 0; i < registeredUserDetails.length; i++) {
      const registeredId = registeredUserDetails[i]["_id"];
      if (
        !classAttendedUser.includes(registeredId) &&
        attendedUser.includes(registeredId)
      ) {
        newAttendUser.push(registeredId);
      } else if (
        classAttendedUser.includes(registeredId) &&
        !attendedUser.includes(registeredId)
      ) {
        deleteAttendUser.push(registeredId);
      }
    }
    const query = {
      id: dayDetails._id,
      attended_user: newAttendUser,
      delete_attended_user: deleteAttendUser,
    };
    const res = await changeUserClassDayDetail(query);
	fetchDayDetails()
  };

  useEffect(() => {
    setClassAttendedUser(dayDetails["attended_user"]);
    setAttendedUser(dayDetails["attended_user"]);
  }, [dayDetails]);


  return (
    <div className={style.seriesDateTable}>
      <div className={style.editAndAttendaceDiv}>
        <div>
          	<label htmlFor="markAllAttended"> 
			<input
				type="checkbox"
				id="markAllAttended"
				name="markAllAttended"
				checked={registeredUserDetails?.length === attendedUser?.length}
				onChange={handleAttendedCheckboxChange}
			></input>
			Mark all attended
			</label>
        </div>
        <div className={style.saveButtonDiv} onClick={() => handleSave()}>
          <AppPrimaryButton title="Save" />
        </div>

      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Student name</th>
            <th>Attended</th>
          </tr>
        </thead>
        <tbody>
          {registeredUserDetails.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/user/${data.username}`)}
                >
                  {data.name}
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={attendedUser?.includes(data["_id"])}
                    onChange={() => handleAttendUser(data["_id"])}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DropdownTableComponent;
