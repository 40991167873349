import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import update from "immutability-helper";
import * as yup from "yup";

import style from "./a.module.scss";

import Form from "./components/seriesForm";
import getClassDetails from "./contexts";
import { updateClassDetails } from "./contexts";

const Series = () => {
  const { id, classType } = useParams();
	const {userName} = useParams();
	const [classDetails, setClassDetails] = useState();
	const navigate = useNavigate();

	const seriesFormSchema = yup.object().shape({
		title: yup.string().required(),
		startDate: yup.string().required(),
		endDate: yup.string().required(),
		startTime: yup.string().required(),
		endTime: yup.string().required(),
		description: yup.string().required(),
		level: yup.string().required(),
		location: yup.string().required(),
		mats: yup.number().required(),
		price: yup.number().required(),
		private: yup.boolean().required(),
	});

	const [values, setValues] = useState({
		title: "",
		startDate: "",
		endDate: "",
		startTime: "",
		endTime: "",
		description: "",
		level: "",
		location: "",
		mats: "",
		price: "",
		private: "",
	});

	const [errors, setErrors] = useState({
		title: false,
		startTime: false,
		startDate: false,
		endDate: false,
		endTime: false,
		description: false,
		level: false,
		location: false,
		mats: false,
		price: false,
		private: false,
	});

	useEffect(() => {
		const getUserData = async (id) => {
		const classDetail = await getClassDetails(id, classType);
		setClassDetails(classDetail[0]);
		const updatedValue = {
			title: classDetail[0].name,
			startDate: classDetail[0].start_date,
			endDate: classDetail[0].end_date,
			startTime: classDetail[0].start_time || "12:00",
			endTime: classDetail[0].end_time || "12:00",
			description: classDetail[0].details,
			level: classDetail[0].level || "Beginner",
			location: classDetail[0].location,
			mats: classDetail[0].number_of_mats_allowed || 0,
			price: classDetail[0].price || 0,
			private: classDetail[0].is_private || false,
		};
		setValues((values) => ({
			...values,
			...updatedValue,
		}));
		};
		getUserData(id);
	}, []);

	const saveDetails = async (details) => {
		let formData = {
		id: id,
		name: details.title,
		start_date: details.startDate,
		end_date: details.endDate,
		start_time: details.startTime,
		end_time: details.endTime,
		details: details.description,
		level: details.level,
		location: details.location,
		number_of_mats_allowed: details.mats,
		price: details.price,
		is_private: details.private,
		};
		const res = await updateClassDetails(formData, classType);
		if (res) {
			const navigateUrl = "/"+userName+"/class/series/"+classDetails._id
			navigate(navigateUrl);
		}
	};

	const onFieldChange = useCallback((fieldName, value) => {
		setValues((prevValues) =>
		update(prevValues, {
			[fieldName]: {
			$set: value,
			},
		})
		);
	}, []);

	const onSubmit = useCallback(
		async (event) => {
		event.preventDefault();
		const isFormValid = await seriesFormSchema.isValid(values, {
			abortEarly: false,
		});
		console.log(errors);
		if (isFormValid) {
			saveDetails(values);
		} else {
			seriesFormSchema.validate(values, { abortEarly: false }).catch((err) => {
				const error = {};
				err.inner.forEach((err) => {
					error[err.path] = err.message;
				});
				setErrors((prevErrors) =>
					update(prevErrors, {
					$set: error,
					})
				);
				});
		}
		},
		[values]
	);
  return (
    <div>
      <Form
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
		  />
    </div>
  )
}

export default Series
