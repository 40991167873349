import React from 'react'
import AppPrimaryButton from '../../components/AppPrimaryButton';
import style from './a.module.scss'
//import assets
import createYoga from '../../assets/Illustrations/createpage-illustrations.svg'
import 'react-toastify/dist/ReactToastify.css';
const CreateClass = () => {
  return (
    <div className={style.createClass}>
      <h1>Create Your Own Classes</h1>
      <img src={createYoga}></img>
      <div className={style.createButtonDiv}>
        <div className={style.singleButtonDiv}>
          <a href="/create/dropin">
            <div className={style.classDefinition}>
            	<h3>Create Drop-in</h3>
				<p>Single Classes</p>
            </div>
          </a>
        </div>
        <div className={style.singleButtonDiv}>
          <div>
            <a href="/create/series">
              <div className={style.classDefinition}>
                <h3>Create Series</h3>
				<p>Multiple class events</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default CreateClass;