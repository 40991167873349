import React, { memo, useEffect, useState } from "react";

//import styles
import style from "./a.module.scss";

//import components
import { CheckboxToggle } from "react-rainbow-components";
import Modal from "../../components/AppModal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import getClassDetails, {
  sendClassRequest,
  getUserDetails,
  updateStudentClassRequest,
  deleteClass,
} from "./contexts";
import AppPrimaryButton from "../../components/AppPrimaryButton";
import SeriesTableComponent from "./components/series/TableComponent";
import SeriesTableDateComponent from "./components/series/TableDateComponent";

import watchIcon from "../../assets/Icons/watchIcon.svg";
import locationIcon from "../../assets/Icons/locationIcon.svg";
import matIcon from "../../assets/Icons/matIcon.svg";
import shareIcon from "../../assets/Icons/shareIcon.svg";
import editIcon from "../../assets/Icons/editIcon.svg";
import deleteIcon from "../../assets/Icons/trashIcon.svg";
import { UserAbout } from "../../contexts/AppUserAboutContext";
import { getLocalValue } from "../../utils/AppLocalStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToggleButton = (props) => {
  const [value, setValue] = useState(true);
  const onOptionChange = (e) => {
    setValue(!value);
  };

  useEffect(() => {
    props.onFieldChange("private", value);
  }, [value]);
  return (
    <div>
      <p>Make class private</p>
      <CheckboxToggle
        id="checkbox-toggle-component-1"
        value={value}
        onChange={onOptionChange}
      />
    </div>
  );
};

const Series = () => {
  const { id, classType, userName } = useParams();
  const { isOtherUser, fetchUser } = UserAbout();
  const [classDetails, setClassDetails] = useState();
  const [teacherDetails, setTeacherDetails] = useState();
  const editURL = `${id}/edit`;
  const navigate = useNavigate();


  const ShareModel = () => {
    const shareModelIcon = <img src={shareIcon}></img>;
    const [isCopiedClicked, setIsCopiedClicked] = useState(false);

    const handleCopy = () => {
      setIsCopiedClicked(true);
      navigator.clipboard.writeText(window.location.href);
    };
    return (
      <Modal title={shareModelIcon} modelTitle="Share the class link">
        <div className={style.shareProfileModal}>
          <input value={window.location.href}></input>
          <div className={style.renewButton}>
            <div className={style.modalContinueButton} onClick={handleCopy}>
              <AppPrimaryButton
                title={isCopiedClicked ? "Copied" : "Copy"}
                fill={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const [values, setValues] = useState({
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    description: "",
    level: "",
    location: "",
    mats: "",
    price: "",
    private: "",
    teacher_id: "",
  });

  const getStyleForLevel = (level) => {
    let styleClass = "";
    if (level === "Beginner") {
      styleClass = style.beginnerLevel;
    } else if (level === "Intermediate") {
      styleClass = style.intermediateLevel;
    } else if (level === "Advance") {
      styleClass = style.advancedLevel;
    }
    return styleClass;
  };

  useEffect(() => {
    const getUserData = async (id) => {
      const classDetail = await getClassDetails(id, classType);
      setClassDetails(classDetail[0]);
      const teacherId = classDetail[0].teacher_id;
      const updatedValue = {
        title: classDetail[0].name,
        startDate: classDetail[0].start_date,
        endDate: classDetail[0].end_date,
        startTime: classDetail[0].start_time,
        endTime: classDetail[0].end_time,
        description: classDetail[0].details,
        level: classDetail[0].level,
        location: classDetail[0].location,
        mats: classDetail[0].number_of_mats_allowed,
        price: classDetail[0].price,
        private: classDetail[0].is_private,
      };
      setValues((values) => ({
        ...values,
        ...updatedValue,
      }));
      const teacherDetail = await getUserDetails(teacherId);
      setTeacherDetails(teacherDetail[0]);
    };
    getUserData(id);
  }, []);

  useEffect(() => {
    fetchUser(userName);
  });

  const handleDeleteClass = async() => {
    const deleteRes = await deleteClass(id,'dropin')
    if(deleteRes){
      toast.success("Delete successfully")
      navigate(-1);
    }
  }

  const OtherUserProfile = () => {
    const studentId = getLocalValue("appUser")?.id;
    const [isUserRegistered, setIsUserRegistered] = useState(false);
    const [isUserRequested, setIsUserRequested] = useState(false);
    useEffect(() => {
      if (classDetails?.registered_user?.includes(studentId)) {
        setIsUserRegistered(true);
      } else if (classDetails?.requested_user?.includes(studentId)) {
        setIsUserRequested(true);
      }
    }, [classDetails]);
    const sendRequest = async () => {
      const studentId = getLocalValue("appUser")?.id;
      if (studentId) {
        const class_res = await sendClassRequest(classType, id, studentId);
        if (class_res._id) {
          setIsUserRequested(true);
          toast.success("Request sent successfully");
        }
        const student_res = await updateStudentClassRequest(
          classType,
          id,
          studentId
        );
      } else {
        toast.error("Please login to register for this class");
      }
    };
    const RegisterClassComponent = <AppPrimaryButton title="Register for the class" fill={true} />

    return (
      <div>
        <div className={style.classCardDetails}>
          <div className={style.classDetails}>
            <p>{teacherDetails?.name}</p>
            <div>
              <img src={watchIcon} alt="watch-icon" />
              <p>
                {values.startTime} - {values.endTime}
              </p>
            </div>
            <div>
              <img src={matIcon} alt="mat-icon" />
              <p>Mats available : {values.mats}</p>
            </div>
            <div>
              <img src={locationIcon} alt="location-icon" />
              <p>{values.location}</p>
            </div>
          </div>
          <div className={style.overviewCard}>
            <div
              className={`${style.levelBg} ${getStyleForLevel(values.level)}`}
            >
              <p>{values.level}</p>
            </div>
            <h2>₹{values.price}</h2>
            <p>{values.startDate} - {values.endDate}</p>
          </div>
        </div>
        <div className={style.classDescription}>
          <h4>{values.title}</h4>
          <p>{values.description}</p>
        </div>
        {isUserRegistered ? (
          <div>
            <AppPrimaryButton title="Registered" fill={false} />
          </div>
        ) : (
          <>
            {isUserRequested ? (
              <div>
                <AppPrimaryButton title="Requested" fill={false} />
              </div>
            ) : (
              <Modal title={RegisterClassComponent} modelTitle="Register for the class">
                <div style={{margin:"2em 0"}}>
                  <h3 style={{fontSize:"1.5em", margin:"1em 0"}}>Are you sure to register for the class.</h3>
                  <p>Your email-id and mobile number will be send to the teacher</p>
                </div>
                <div onClick={sendRequest}>
                  <AppPrimaryButton title="Yes register for the class" fill={true} />
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
    );
  };

const UserMainProfile = () => {
    const [isRegistrationSelected, setIsRegistrationSelected] = useState(true);
    return (
    <div className={style.classDetailsMain}>
        <div className={style.toggleButtonSection}>
        {/* <CheckboxToggle label="Make private Drop-in " labelAlignment="left" /> */}
        <div>
            <ShareModel />
            <a href={editURL}>
            <img src={editIcon} alt="edit-icon"></img>
            </a>
            <div style={{cursor:"pointer"}} onClick={handleDeleteClass}><img src={deleteIcon} alt="delete-icon"></img></div>

        </div>
        </div>

        <div className={style.desktopViewDiv}>
            <div className={style.classInformation}>
                <div className={style.classCardDetails}>
                    <div className={style.classDetails}>
                        <p>{teacherDetails?.name}</p>
                        <div>
                            <img src={watchIcon} alt="watch-icon" />
                            <p>
                                {values.startTime} - {values.endTime}
                            </p>
                        </div>
                        <div>
                            <img src={matIcon} alt="mat-icon" />
                            <p>Mats available : {values.mats}</p>
                        </div>
                        <div>
                            <img src={locationIcon} alt="location-icon" />
                            <p>{values.location}</p>
                        </div>
                    </div>
                    <div className={style.overviewCard}>
                        <div
                            className={`${style.levelBg} ${getStyleForLevel(
                                values.level
                            )}`}
                            >
                            <p>{values.level}</p>
                        </div>
                        <h2>₹{values.price}</h2>
                        <p style={{fontSize:"0.6em", margin:"0.5em 0"}}>{values.startDate} - {values.endDate}</p>
                    </div>
                </div>
                <div className={style.classDescription}>
                    <h4>{values.title}</h4>
                    <p>{values.description}</p>
                </div>
                <div style={{margin:"0 0 1.5em 0"}}>
                    <SeriesTableDateComponent startDate={values.startDate} endDate={values.endDate}/>
                </div>
            </div>
            <div className={style.studentsTableSection}>
            <div className={style.switchButtons}>
            <div
                className={
                isRegistrationSelected
                    ? style.registeredButton
                    : style.requestedButton
                }
                onClick={() => setIsRegistrationSelected(true)}
            >
                Registrations
            </div>
            <div
                className={
                !isRegistrationSelected
                    ? style.registeredButton
                    : style.requestedButton
                }
                onClick={() => setIsRegistrationSelected(false)}
            >
                Requests
            </div>
            </div>
            <div className={style.studentNameSection}>
            <SeriesTableComponent
                classDetails={classDetails}
                isRegistrationSelected={isRegistrationSelected}
            />
            </div>
        </div>
        </div>
    </div>
    );
};

  return (
    <div style={{ maxWidth: "60em", margin: "auto" }}>
        {isOtherUser ? <OtherUserProfile /> : <UserMainProfile />}
    </div>
);
};

export default Series;
