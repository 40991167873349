import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AppPrimaryButton from "../../components/AppPrimaryButton";
import AppStatCard from "../../components/AppStatCard";

import style from "./a.module.scss";

import userProfileillustration from "../../assets/Illustrations/userProfile-illustrations.svg";
import shareIcon from "../../assets/Icons/shareIcon.svg";
import editIcon from "../../assets/Icons/editIcon.svg";
import embedIcon from "../../assets/Icons/embedIcon.svg"
import requestIcon from "../../assets/Icons/requestedIcon.svg"
import registeredIcon from "../../assets/Icons/registeredIcon.svg"
import { UserDetail } from "../../contexts/AppUserDetailContext";
import Modal from "../../components/AppModal/Modal";
import { UserAbout } from "../../contexts/AppUserAboutContext";
import { Width } from "devextreme-react/chart";

const UserProfile = () => {
  const { userName } = useParams();
  const userProfileEditURL = window.location.href + "/edit";
  const [isLoading, setIsLoading] = useState(true);

  const { userDetails, FetchUserDetails } = UserDetail();
  const { isOtherUser, fetchUser } = UserAbout();

  useEffect(() => {
    setIsLoading(true);
    const query = { username: userName };
    FetchUserDetails(query);
    fetchUser(userName);
    setIsLoading(false);
  }, []);

  const dateObj = new Date(userDetails.lastSubDate);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });
  const ShareModel = () => {
    const shareModelIcon = <img src={shareIcon} alt="share-icon"></img>;
    const [isCopiedClicked, setIsCopiedClicked] = useState(false);

    const handleCopy = () => {
      setIsCopiedClicked(true);
      navigator.clipboard.writeText(window.location.href);
    };

    if (isLoading) {
      return <>Loading ..... </>;
    }
    return (
      <Modal title={shareModelIcon} modelTitle="Share the profile">
        <div className={style.shareProfileModal}>
          <input value={window.location.href}></input>
          <div className={style.renewButton}>
            <div className={style.modalContinueButton} onClick={handleCopy}>
              <AppPrimaryButton
                title={isCopiedClicked ? "Copied" : "Copy"}
                fill={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const EmbedLink = () => {
    const shareModelIcon = <AppPrimaryButton title="Embed Classes" imageSrc={embedIcon} />;
    const [isCopiedClicked, setIsCopiedClicked] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Drop-in");
    const origin = window.location.origin;
    const iframeURL = `<iframe src="${origin}/${userName}/class/${selectedOption == "Drop-in" ? "dropin" : "series"}" title="${selectedOption == "Drop-in"? "Drop-in Classes": "Series Classes"}"height={500} width="100%"></iframe>`;

    const toggleContent = (option) => {
      setSelectedOption(option);
    };

    const handleCopy = () => {
      setIsCopiedClicked(true);
      navigator.clipboard.writeText(iframeURL);
    };

    if (isLoading) {
      return <>Loading ..... </>;
    }
    return (
      <Modal title={shareModelIcon} modelTitle="Embed Classes">
        <div className={style.shareProfileModal}>
          <div className={style.miniRadioButtons}>
            <p className={style.selectTypeText}>Select type</p>
            <div className={style.selectOption}>
              <div>
                <label style={{display:"flex", width:"6em"}}>
                  <input
                    type="radio"
                    name="options"
                    value="Drop-in"
                    checked={selectedOption == "Drop-in"}
                    onChange={() => toggleContent("Drop-in")}
                    style={{accentColor:"black"}}
                  />
                  <p style={{whiteSpace:"nowrap"}}>
                  Drop-in
                  </p>
                </label>
              </div>
              <div>
                <label style={{display:"flex", width:"6em"}}>
                  <input
                    type="radio"
                    name="options"
                    value="Series"
                    checked={selectedOption == "Series"}
                    onChange={() => toggleContent("Series")}
                    style={{accentColor:"black"}}
                  />
                  <p style={{whiteSpace:"nowrap"}}>
                    Series
                  </p>
                </label>
              </div>
            </div>
            <div className={style.content} id="content">
              <input value={iframeURL}></input>
            </div>
          </div>
          <div className={style.renewButton}>
            <div className={style.modalContinueButton} onClick={handleCopy}>
              <AppPrimaryButton
                title={isCopiedClicked ? "Copied" : "Copy"}
                fill={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const OtherUserProfile = () => {
    const dropinClassURL =
      window.location.protocol + "/" + userName + "/class/dropin";
    const seriesClassURL =
      window.location.protocol + "/" + userName + "/class/series";

    return (
      <div className={style.userProfile}>
        <img
          className={style.userIllustration}
          src={userProfileillustration}
          alt="user-illustration"
        ></img>
        <div className={style.statColumn}>
          <div className={style.namasteDiv}>
            <p>Namaste, {userDetails.name}🙏 </p>
            <div className={style.icons}>
              <div>
                <ShareModel />
              </div>
            </div>
          </div>
          <p style={{ textAlign: "left", color: "grey" }}>
            Unleash your potential and grow with Yogamitra
          </p>
          <div className={style.viewDropSeriesButtons}>
            <a href={dropinClassURL}>
              <AppPrimaryButton title="View dropin" fill={true} />
            </a>
            <a href={seriesClassURL}>
              <AppPrimaryButton title="View series" fill={true} />
            </a>
          </div>
          <h2>Statistics</h2>
          <div className={style.statisticsTwoDivs}>
            <AppStatCard
              value={userDetails.dropinId.length}
              title="Drop-in Created"
            />
            <AppStatCard
              value={userDetails.seriesId.length}
              title="Series Created"
            />
          </div>
        </div>
      </div>
    );
  };

  console.log(formattedDate)

  const UserMainProfile = () => {
    const registerClassURL =
      window.location.protocol + "/" + userName + "/class/registered";
    const requestedClassURL =
      window.location.protocol + "/" + userName + "/class/requested";
    return (
      <div className={style.userProfile}>
        <img
          className={style.userIllustration}
          src={userProfileillustration}
          alt="user-illustration"
        ></img>
        <div className={style.statColumn}>
          <div className={style.namasteDiv}>
            <p>Namaste, {userDetails.name}🙏 </p>
            <div className={style.icons}>
              <div>
                <ShareModel />
              </div>
              {isOtherUser ? null : (
                <a href={userProfileEditURL}>
                  <img src={editIcon} alt="edit-icon"></img>
                </a>
              )}
            </div>
          </div>
          <p
            style={{ textAlign: "left", margin: "0.4em 0 0 0", color: "grey" }}
          >
            Unleash your potential and grow with Yogamitra
          </p>

          <div className={style.registerRequestButtons}>
            <a href={registerClassURL}>
              <AppPrimaryButton title="Registered" imageSrc={registeredIcon} />
            </a>
            <a href={requestedClassURL}>
              <AppPrimaryButton title="Requested" imageSrc={requestIcon} />
            </a>
          </div>
          <div className={style.embedButtonDiv}>
            <EmbedLink />
          </div>
          <h2>Statistics</h2>
          <div className={style.statisticsTwoDivs}>
            <AppStatCard
              value={userDetails.dropinId.length}
              title="Drop-in Created"
            />
            <AppStatCard
              value={userDetails.seriesId.length}
              title="Series Created"
            />
          </div>
          <AppStatCard value="0" title="Total number of students Taught" />
          <div className={style.statisticsTwoDivs}>
            <AppStatCard
              value={userDetails.dropinRegisteredClassId.length}
              title="Drop-in Registered"
              background={true}
            />
            <AppStatCard
              value={userDetails.seriesRegisteredClassId.length}
              title="Series Registered"
              background={true}
            />
          </div>
          <div>
			{formattedDate=="Invalid Date"?null:
            <p>Your current subscription ended on {formattedDate}</p>
			}
          </div>
        </div>
      </div>
    );
  };

  return <>{isOtherUser ? <OtherUserProfile /> : <UserMainProfile />}</>;
};

export default UserProfile;
