import React, { useEffect, useState } from "react";

import style from "./a.module.scss";

import ClassCard from "../../components/AppClassCard";
import { useParams } from "react-router-dom";
import { UserDetail } from "../../contexts/AppUserDetailContext";
import fetchUserClasses, { fetchUserDetails } from "./contexts";
import ClassDetails from "../ClassDetails";

const ClassRequested = () => {
  const { userName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [classType, setClassType] = useState("dropin");
  const [classDetail, setClassDetail] = useState([]);

  const [selectedOption, setSelectedOption] = useState("Drop-in");

  const toggleContent = (option) => {
    if (option == "Drop-in") {
      setClassType("dropin");
    } else if (option == "Series") {
      setClassType("series");
    }
    setSelectedOption(option);
  };

  const [userDetails, setUserDetails] = useState({
    id: "",
    name: "",
    username: "",
    email: "",
    seriesRequestedClassId: "",
    dropinRequestedClassId: "",
    phoneNumber: "",
  });

  const FetchUserDetails = async (userName) => {
    let query = {
      username: userName,
    };

    const res = await fetchUserDetails(query);
    const updatedValue = {
      id: res._id || "",
      name: res.name || "",
      username: res.username || "",
      email: res.email || "",
      seriesRequestedClassId: res.series_requested_class_id || [],
      dropinRequestedClassId: res.dropin_requested_class_id || [],
      phoneNumber: res.phone_number || "",
    };
    setUserDetails((userDetails) => ({
      ...userDetails,
      ...updatedValue,
    }));
  };

  const fetchClasses = async () => {
    const classesDetails = [];
    let lastFetchDone = false;
    const classArray =
      classType == "dropin"
        ? userDetails.dropinRequestedClassId
        : userDetails.seriesRequestedClassId;
    for (var i = 0; i < classArray.length; i++) {
      const classDetails = await fetchUserClasses(classArray[i], classType);
      let query = {
        id: classDetail[0]?.teacher_id,
      };
  
      const res = await fetchUserDetails(query);
      classDetails[0]["teacherUserName"]=res.username
      const redirectURL = window.location.origin + "/" + res.username + "/class/" + classType + "/" + classDetails[0]._id
      classDetails[0]["redirectURL"]=redirectURL
      if (i == classArray.length - 1) {
        lastFetchDone = true;
      }
      classesDetails.push(classDetails[0]);
    }
    if (lastFetchDone || classArray.length == 0) {
      setIsLoading(false);
    }
    setClassDetail(classesDetails);
  };

  useEffect(() => {
    setIsLoading(true);
    FetchUserDetails(userName);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchClasses();
  }, [userDetails, classType]);

  const ClassDataComp = () => {
    return (
      <div className={style.classDataComp}>
        {classDetail.length == 0 ? (
          <div>No classes</div>
        ) : (
          <div className={style.classesList}>
            {classDetail.map((card, index) => (
              <ClassCard
                id={card?._id}
                key={index}
                title={card?.name}
                date={card?.start_date}
                time={card?.start_time}
                matsLeft={card?.number_of_mats_allowed}
                location={card?.location}
                level={card?.level}
                price={card?.price}
                username={userName}
                teacherUserName={card?.teacherUserName}
                redirectURL={card?.redirectURL}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.requestedClassPage}>
      <div className={style.requestedClassColumnar}>
        <div className={style.switchButtons}>
          <div className={selectedOption === "Drop-in" ? style.selectedButton : style.unselectedButton} onClick={() => toggleContent("Drop-in")}>Drop-in</div>
          <div className={selectedOption === "Series" ? style.selectedButton : style.unselectedButton} onClick={() => toggleContent("Series")}>Series</div>
        </div>
        

        </div>
      <div className={style.classinfo}>
          {isLoading ? <div>Loading ... </div> : <ClassDataComp />}
      </div>
    </div>
  );
}

export default ClassRequested