import * as React from "react";
import { useState, memo } from "react";
import { useCallback, useEffect } from "react";
import update from "immutability-helper";

import { SeriesForm } from "./components/seriesForm";
import * as yup from "yup";
import saveClass, { saveClassInUser } from "./contexts";
import { useNavigate } from "react-router-dom";
import { UserDetail } from "../../contexts/AppUserDetailContext";
import { getLocalValue } from "../../utils/AppLocalStorage";

const Series = memo(() => {
  const navigate = useNavigate()
  const {userDetails, FetchUserDetails} = UserDetail();
  const classType = 'series'


  useEffect(()=>{
    const email = getLocalValue('appUser').email;
    const query={ "email": email}
    FetchUserDetails(query)
  },[])

  // const [value, setValue] = useState({
  //   startDate: null,
  //   endDate: null,
  // });

  // const handleValueChange = (newValue) => {
  //   setValue(newValue);
  // };

  const saveDetails = async (details) => {
    let formData = {
      name: details.title,
      start_date: details.startDate,
      end_date: details.endDate,
      start_time: details.startTime,
      end_time: details.endTime
    };
    const res = await saveClass(formData, classType);
    const userRes = await saveClassInUser(res._id, classType)
    if (res._id) {
      const navigateURL = `/${userDetails.username}/class/series/${res._id}/edit`
      navigate(navigateURL)
    }
  };

  const seriesFormSchema = yup.object().shape({
    title: yup.string().required(),
    startDate: yup
    .string()
    .required("Start Date is required")
    .test("date-length", "Date must be at least 2 characters", (value) => {
      return !value || value.length >= 2;
    }),
  endDate: yup
    .string()
    .required("End Date is required")
    .test("date-length", "Date must be at least 2 characters", (value) => {
      return !value || value.length >= 2;
    }),
    startTime: yup.string().required("Start time number is required"),
    endTime: yup.string().required("End time number is required")
  });

  const [values, setValues] = useState({
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  });

  const onFieldChange = useCallback((fieldName, value) => {
    setValues((prevValues) =>
      update(prevValues, {
        [fieldName]: {
          $set: value,
        },
      })
    );
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const isFormValid = await seriesFormSchema.isValid(values, {
        abortEarly: false,
      });
      console.log(values)
      console.log(errors)
      if (isFormValid) {
        saveDetails(values);
      } else {
        seriesFormSchema.validate(values, { abortEarly: false }).catch((err) => {
          const error = {};
          err.inner.forEach((err) => {
            error[err.path] = err.message;
          });
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: error,
            })
          );
        });
      }
    },
    [values]
  );
  return (
    <div>
      <SeriesForm
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
        seriesFormSchema={seriesFormSchema}
      />
    </div>
  );
}) 

export default Series;