import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../../../configs/EnvVariables";
import { getLocalValue } from "../../../utils/AppLocalStorage";

function getDateAfterMonths(months) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let targetYear = currentYear;
  let targetMonth = currentMonth + months;
  if (targetMonth > 11) {
    targetYear += Math.floor(targetMonth / 12);
    targetMonth = targetMonth % 12;
  }

  
  const targetDate = new Date(targetYear, targetMonth , currentDate.getDate());
  return targetDate;
}

const SubscribeUser = async (numberOfMonths) => {
  const futureDate = getDateAfterMonths(numberOfMonths);
  const userId = getLocalValue("appUser").id;
  const data = {
    id: userId,
    last_sub_date: futureDate,
  };
  let apiUrl = `${API_HOST}/api/user`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = true;
    })
    .catch((error) => {
        resData = false;
    });
    return resData

};

export default SubscribeUser;
