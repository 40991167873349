//import react
import React from "react";
//import styles
import style from "./a.module.scss";
import AppDatePicker from "./components/AppDatePicker";
import AppTimePicker from "./components/AppTimePicker";
import AppTextArea from "./components/AppTextArea";
import ToggleButton from "./components/ToggleButton";

const AppInputField = ({
	name,
	fieldName,
	id,
	label,
	type,
	placeholder,
	value,
	onChange,
	hasError,
	labelText,
	typeOfField,
	}) => {

	// const RadioButton = ({type,id,name,value,onChange}) => {
	// 	return(
	// 		<>
	// 			<input 
	// 				type={type}
	// 				id={id} 
	// 				name={name}
	// 				value={value} 
	// 				checked={name===value}
	// 				onChange={onChange}
	// 			/>
	// 			<label htmlFor={value}>{value}</label>
	// 		</>
	// 	)
	// }
	//render
	return (
		<div className={style.appInputField}>
		<p>
			<label htmlFor={fieldName}>{label}</label>
		</p>
		{typeOfField == "datePicker" ? (
			<AppDatePicker value={value} onChange={onChange} label={label}/>
			) : null}
		{typeOfField == "timePicker" ? (
			<AppTimePicker value={value} onChange={onChange} label={label}/>
			) : null}
		{typeOfField == "textArea" ? (
			<AppTextArea value={value} onChange={onChange} label={label}/>
			) : null}
		{typeOfField == "toggleButton" ? (
			<ToggleButton value={value} onChange={onChange}/>
			) : null}
		{typeOfField == null || typeOfField == "input" ? (
			<input id={id} name={name} type={type} placeholder={placeholder} value={value} onChange={onChange}></input>
		) : null}
		{hasError && (
			<p
			className={style.errorTag}
			>{`${hasError}`}</p>
		)}
		</div>
	);
};

export default AppInputField;
