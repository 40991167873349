import React, { useEffect, useState } from "react";
import style from "../../a.module.scss";
import editIcon from "../../../../assets/Icons/editIcon.svg";
import AppPrimaryButton from "../../../../components/AppPrimaryButton";
import { useParams } from "react-router-dom";
import { fetchClassDays } from "../../contexts";

const SeriesTableDateComponent = (props) => {
  const AttendanceURL = window.location.href + "/date";
  const EditDateURL = window.location.href + "/date/edit";
  const { id, classType } = useParams();
  const { startDate, endDate } = props;
  const [classDateDetails, setClassDateDetails] = useState([]);

  const FetchClassDateDetails = async () => {
    const class_date_res = await fetchClassDays(id, startDate, endDate);
    setClassDateDetails(class_date_res);
  };
  useEffect(() => {
    if (classDateDetails.length == 0) {
      FetchClassDateDetails();
    }
  }, []);

  return (
    <div className={style.tableDateComp}>
      <div className={style.tableDateCompHeader}>
        <h1>Schedule Dates</h1>
        <div className={style.editAndAttendaceDiv}>
          <a href={EditDateURL}>
            <img src={editIcon} alt="edit-icon" />
          </a>
          <div className={style.markAttendanceDiv}>
            <a href={AttendanceURL}>
              <AppPrimaryButton title="Mark Attendance" />
            </a>
          </div>
        </div>
      </div>

      <div className={style.seriesDateTable}>
        <table>
          <thead>
            <tr>
              <th>Start date</th>
              <th>Start time</th>
              <th>End time</th>
            </tr>
          </thead>
          <tbody>
            {classDateDetails.map((event, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? style.evenRow : style.oddRow}
              >
                <td>{event.date}</td>
                <td>{event.start_time}</td>
                <td>{event.end_time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeriesTableDateComponent;
