import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../../../configs/EnvVariables";
import { isDateGreaterThanCurrentDate } from "../../../utils/AppDateCheck";



const checkIsPaidUser = async (query) => {
	try {
		const response = await axios.post(`${API_HOST}/api/user/search`, {
			query: query,
		});
		if (response && response.data.length>0) {
            const userData = response.data[0]
            let last_sub_date = userData.last_sub_date
            return isDateGreaterThanCurrentDate(last_sub_date)
		}
        else{
            return true
        }
	} catch (error) {
		console.log(error);
	}
};

export default checkIsPaidUser