import * as React from "react";
import { useState, memo } from "react";
import { useCallback, useEffect } from "react";
import update from "immutability-helper";

import { Form } from "./components/dropinForm";
import * as yup from "yup";
import saveClass, { saveClassInUser } from "./contexts";
import { useNavigate, useParams } from "react-router-dom";
import { UserDetail } from "../../contexts/AppUserDetailContext";

const Dropin = memo(() => {
  const navigate = useNavigate()
  const {userDetails, FetchUserDetails} = UserDetail();
  const {classType} = useParams()

  useEffect(()=>{
    FetchUserDetails()
  },[])

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const saveDetails = async (details) => {
    let formData = {
      name: details.title,
      start_date: details.date,
      start_time: details.startTime,
      end_time: details.endTime,
    };
    const res = await saveClass(formData, classType);
    const userRes = await saveClassInUser(res._id, classType)
    if (res._id) {
      const navigateURL = `/${userDetails.username}/class/dropin/${res._id}/edit`
      navigate(navigateURL)
    }
  };

  const formSchema = yup.object().shape({
    title: yup.string().required(),
    date: yup.string().required("Date is required"),
    startTime: yup.string().required("Start time number is required"),
    endTime: yup.string().required("End time number is required"),
  });

  const [values, setValues] = useState({
    title: "",
    date: "",
    startTime: "",
    endTime: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    date: false,
    startTime: false,
    endTime: false,
  });

  const onFieldChange = useCallback((fieldName, value) => {
    setValues((prevValues) =>
      update(prevValues, {
        [fieldName]: {
          $set: value,
        },
      })
    );
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const isFormValid = await formSchema.isValid(values, {
        abortEarly: false,
      });
      if (isFormValid) {
        saveDetails(values);
      } else {
        formSchema.validate(values, { abortEarly: false }).catch((err) => {
          const error = {};
          err.inner.forEach((err) => {
            error[err.path] = err.message;
          });
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: error,
            })
          );
        });
      }
    },
    [values]
  );

  return (
    <div>
      <Form
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
        formSchema={formSchema}
      />
    </div>
  );
});

export default Dropin;