import axios from "axios";
import _ from "lodash";
// importing ENV variables
import { API_HOST } from "../configs/EnvVariables";
import { getLocalValue } from "../utils/AppLocalStorage";

import { useContext, createContext, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { UserAuth } from "./AppFirebaseContext";

const fetchUserDetails = async (query) => {
    try {
        const response = await axios.post(`${API_HOST}/api/user/search`, {
            query: query,
        });
        if (response && response.data) {
            return response.data[0]
        }
    } catch (error) {
        console.log(error);
    }
};


const UserContext = createContext(0)

export const UserContextProvider= ({children}) => {
    
    const { user } = UserAuth()
    const [isAppLoading,setIsAppLoading] = useState(true)
    const [userDetails, setUserDetails] = useState({
        id: "",
        name: "",
        username: "",
        email: "",
        seriesId: "",
        dropinId: "",
        phoneNumber: "",
        lastSubDate: "",
        dropinRequestedClassId:"",
        dropinRegisteredClassId: "",
        seriesRequestedClassId:"",
        seriesRegisteredClassId: ""
    });

    const FetchUserDetails = async (query) => {
        const res = await fetchUserDetails(query);
        const updatedValue = {
            id: res._id || "",
            name: res.name || "",
            username: res.username || "",
            email: res.email || "",
            seriesId: res.series_class_id || [],
            dropinId: res.dropin_class_id || [],
            phoneNumber: res.phone_number || "",
            lastSubDate: res.last_sub_date || "",
            dropinRequestedClassId: res.dropin_requested_class_id || [],
            dropinRegisteredClassId: res.dropin_registered_class_id || [],
            seriesRequestedClassId: res.series_requested_class_id || [],
            seriesRegisteredClassId: res.series_registered_class_id || []
        };
        setUserDetails((userDetails) => ({
            ...userDetails,
            ...updatedValue,
        }));
        setIsAppLoading(false)
    };
    
    useEffect(()=>{
        if(getLocalValue('appUser')==undefined){
            setIsAppLoading(false)  
        }
    },[])
    
    return (
        <UserContext.Provider value={{userDetails, FetchUserDetails, isAppLoading, setIsAppLoading}}>
            {children}
        </UserContext.Provider>
    )
}

export const UserDetail = () => {
    return useContext(UserContext)
}