import { memo, useState } from "react";
import style from '../../../a.module.scss'

import { Field } from "../../../../../components/AppField";

import AppPrimaryButton from "../../../../../components/AppPrimaryButton";


//import assets
import googleLogo from '../../../../../assets/Icons/google-logo.svg';


//imports from context
import { UserAuth } from "../../../../../contexts/AppFirebaseContext";



const Form = (props) => {
	//user authentication function 
	const {googleSignUp} = UserAuth()


	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleCheckboxChange = (event) => {
		setPasswordVisible(!passwordVisible );
	  };

	
	
	const OrComponent = () => {
		return <div className={style.orComponent}>
			<div></div>
			<p>Or</p>
			<div></div>
		</div>
	}
	


	const _handleGoogleSignUp = async () => {
		try {
			await googleSignUp();
		}
		catch (error) {
			console.log(error)
		}
	}
	return (
		<div >
			<form onSubmit={props.onSubmit} noValidate>
				<h1>Let's Get Started</h1>
				<h2>Create an account</h2>
				<div>
					<Field
						labelText="Email"
						fieldType="text"
						fieldName="email"
						placeholder="Enter your email"
						fieldValue={props.values.email}
						hasError={props.errors.email}
						onFieldChange={props.onFieldChange}
					/>
					<Field
						labelText="Password"
						fieldType={passwordVisible ? 'text' : 'password'}
						fieldName="password"
						placeholder="Password"
						fieldValue={props.values.password}
						hasError={props.errors.password}
						onFieldChange={props.onFieldChange}
					/>
					<Field
						labelText="Confirm Password"
						fieldType={passwordVisible ? 'text' : 'password'}
						fieldName="confirmPassword"
						placeholder="Re-enter your password"
						fieldValue={props.values.confirmPassword}
						hasError={props.errors.confirmPassword}
						onFieldChange={props.onFieldChange}
					/>
					<div className={style.showPasswordDiv}>
						<input type="checkbox" id="markAllPaid" name="markAllPaid" onChange={handleCheckboxChange} ></input>
						<label htmlFor="markAllPaid">Show Password</label>
					</div>
				</div>
				
				<div className={style.info}>Click “Sign Up” to agree Yogamitra’s <a style={{color:"blue"}} href={'/termsandconditions'}>Terms of service</a> and acknowledge that Yogamitra’s <a style={{color:"blue"}} href={'/privacypolicy'}>Privacy Policy</a> applies to you.</div>
				<div className={style.signupButtonDiv}>
					<button type="submit">
						<AppPrimaryButton title="Sign Up" fill={true} />
					</button>

				</div>
				<OrComponent />
				<div style={{ marginTop: '1em' }} onClick={_handleGoogleSignUp}><AppPrimaryButton title="Sign Up with Google" imageSrc={googleLogo} /></div>
				<div className={style.signInText}>Already have an account? <a style={{color:"blue"}} href={'/signin'}>Sign In</a></div>
			</form>
		</div>
	)
}

export default memo(Form)
