import { useEffect, useState } from "react";
//import from react router dom
import { Navigate, Route, Routes } from "react-router-dom";
//import styles
import "./App.css";
//import pages
import Home from "./pages/Home";
import UserSignIn from "./pages/UserSignIn/index.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import UserProfile from "./pages/UserProfile";
import UserEditProfile from "./pages/UserEditProfile";
import CreateClass from "./pages/CreateClass";
import ClassesList from "./pages/ClassesList";
import ClassDetails from "./pages/ClassDetails";
import EditClassDetails from "./pages/EditClassDetails";
import CreateClassScheduler from "./pages/CreateClassScheduler";
import ClassDatesEdit from "./pages/ClassDatesEdit";
import ClassDates from "./pages/ClassDates";
import ClassRegistered from "./pages/ClassRegistered";
import ClassRequested from "./pages/ClassRequested";
import Test from "./pages/TestPage";
//import AppComponets
import AppHeader from "./components/AppHeader";
//import Context
import { UserAuth } from "./contexts/AppFirebaseContext";
import UserSignUp from "./pages/UserSignUp";
import { API_HOST, APP_ENV } from "./configs/EnvVariables";
import { getAppUser } from "./contexts/AppUserContext";
import AppPaidUser from "./components/AppPaidUser";
import AppUserLogin from "./components/AppUserLogin";
import { ToastContainer } from "react-toastify";

function App() {
  const { user } = UserAuth();
  const [appUser, setAppUser] = useState();
  async function loadUser(user) {
    if (user) {
      const item = await getAppUser(user);
      setAppUser(item);
    }
  }

  useEffect(() => {
    if (user?.uid) {
      const userValue = {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
      };
      loadUser(user);
    }
  }, [user]);
  
  //render
  return (
    <div className="App">
      <AppHeader />
      <ToastContainer />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<UserSignIn />} />
          <Route path="/signin/:page" element={<UserSignIn />} />
          <Route path="/signup" element={<UserSignUp />} />
          <Route path="/signup/:page" element={<UserSignUp />} />
          <Route path="/user/:userName" element={<UserProfile />} />
          <Route
            path="/user/:userName/edit"
            element={
              // <AppUserLogin>
                <UserEditProfile />
              // </AppUserLogin>
            }
          />
          <Route path="/create" element={<CreateClass />} />
          <Route
            path="/create/:classType"
            element={
              // <AppUserLogin>
                <AppPaidUser>
                  <CreateClassScheduler />
                </AppPaidUser>
              // </AppUserLogin>
            }
          />
          <Route path=":userName/class/:classType" element={<ClassesList />} />
          <Route path=":userName/class/registered" element={<ClassRegistered/>} />
          <Route path=":userName/class/requested" element={<ClassRequested/>} />
          <Route path=":userName/class/:classType/:id" element={<ClassDetails />} />
          <Route path=":userName/class/:classType/:id/date" element={<ClassDates />} />
          <Route path=":userName/class/:classType/:id/date/edit" element={<ClassDatesEdit />} />
          <Route
            path=":userName/class/:classType/:id/edit"
            element={
              <AppUserLogin>
                {/* <AppPaidUser> */}
                  <EditClassDetails />
                {/* </AppPaidUser> */}
              </AppUserLogin>
            }
          />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
