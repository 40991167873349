import React from "react";

import Dropin from "./dropin";
import Series from "./series";
import { useParams } from "react-router-dom";

const ClassDetails = () => {
	const {classType} = useParams()
  return (
    <div >
      {classType=='dropin' ? <Dropin /> : <Series />}
    </div>
  );
};

export default ClassDetails;
