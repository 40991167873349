import { useParams } from "react-router-dom";

import Dropin from "./dropin";
import Series from "./series";

const CreateClass = () => {
	const {classType} = useParams()
	return (
		<div style={{marginBottom:"2em"}}>
			{classType=='dropin'?<Dropin />:<Series />}
		</div>
	);
}

export default CreateClass;
