import React, { useEffect, useState } from "react";

import style from "./a.module.scss";
import { Picklist, PicklistOption, DatePicker } from "react-rainbow-components";

const AppDatePicker = ({ value, onChange }) => {

	const initialState = {
		date: new Date(),
		locale: { name: "en-US", label: "English (US)" },
	};

	const containerStyles = {
		maxWidth: 400,
	};

	const [DateValue, setDateValue] = useState();

	useEffect(() => {
		if (value) {
		setDateValue(value);
		} else {
		setDateValue(initialState.date);
		}
	}, [value]);

	return (
		<div className={style.appDatePicker}>
		<div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto" style={containerStyles}>
			<DatePicker
				id="datePicker-1"
				value={DateValue}
				onChange={onChange}
				formatStyle="large"
				locale="en-US"						
			/>
		</div>
		</div>
	);
};

export default AppDatePicker;
