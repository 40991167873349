import React, {useState} from 'react'

import style from './a.module.scss'

import MyModal from './ShowModal';

const Modal = ({children,title,isModelAlwaysOpen, modelTitle}) => {

    let val = isModelAlwaysOpen?true:false;
    const [showModal, setShowModal] =  useState(val);
    const closeModal = () => setShowModal(false);
  return (
    <div className={style.modalButton}>
    
        <button onClick={()=>setShowModal(true)}>{title}</button>
        {showModal && <MyModal modelTitle={modelTitle} closeModal ={isModelAlwaysOpen?null:closeModal} children={children}/>}
    </div>
  )
};

export default Modal