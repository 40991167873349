//import react
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import styles
import style from "./a.module.scss";
//import app components
import AppInputField from "../../components/AppInputField";
import AppPrimaryButton from "../../components/AppPrimaryButton";
//imports from context
import { UserAuth } from '../../contexts/AppFirebaseContext';
//import assets


import SignIn from "./components/SignIn";

const UserSignIn = () => {
    const {page} = useParams()

    //user authentication function 
    const {googleSignIn, facebookSignIn, emailSignIn, emailSignUp, forgotPasword, logOut, errorMessage, user, loginLoading, setErrorMessage, isResetPasswordLinkSent} = UserAuth()
    //handling of sign in with social account
    const _handleGoogleSignIn = async () => {
        try{
            await googleSignIn();
        }
        catch(error){
            console.log(error)
        }
    }
    const _handleEmailSignIn = async (userEmail, userPassword) => {
        try{
            await emailSignIn(userEmail,userPassword);
        }
        catch(error){
            console.log(error)
        }
    }
    const _handleForgotPassword = async (userEmail) => {
        try{
            await forgotPasword(userEmail)
        }
        catch(error){
            console.log(error)
        }
    }


    const ForgotPassword = () => {
        const [email, setEmail] = useState('');

        return (
            <div>
                <h1>Forgot Password</h1>
                <div className={style.inputFields}>
                    <AppInputField label="Email Id" placeholder="john@gmail.com" type="text" onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div style={{marginTop:'2em'}} onClick={()=>_handleForgotPassword(email)}><AppPrimaryButton title="Submit" fill={true}/></div>
            </div>
        )
    }

    const CreateNewPassword = () => {
        return (
            <div>
                <h1>Create New Password</h1>
                <div className={style.inputFields}>
                    <AppInputField label="Password"  type="text"/>
                    <AppInputField label="Re-enter Password"  type="text"/>
                </div>
                <div style={{marginTop:'2em'}}><AppPrimaryButton title="Submit" fill={true}/></div>
            </div>
        )
        
    }

    const RenderComponent = () => {
        switch (page) {
            case undefined:
                return <SignIn />
            case 'forgotpassword':
                return <ForgotPassword />
            case 'details':
                return <CreateNewPassword />
            default:
                <SignIn />
                break;
        }
    }
    //render
    return (
        <div className={style.userSignIn}>
            <RenderComponent />
        </div>
    );
};

export default UserSignIn;
