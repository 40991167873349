// react importes
import React from "react";
// import styles
import style from "./a.module.scss";
//import assets
import yogamitraLogo from "../../assets/Icons/yogamitra-logo.svg";
import yogamitraText from "../../assets/Icons/yogamitra-text.svg";
import Menubar from "./components/Menubar";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
  // const { logOut } = UserAuth();
  const navigate = useNavigate();

  // const _handleSignOut = async () => {
  //   try {
  //     await logOut();
  //     localStorage.removeItem("user");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  return (
    <div className={style.appHeader} style={{justifyContent:"space-between"}}>
      <div className={style.yogamitraBranding} onClick={() => navigate("/")}>
        <img src={yogamitraLogo} alt="Yogamitra-logo"></img>
        <img src={yogamitraText} alt="Yogamitra-text"></img>
      </div>
        <Menubar />
    </div>
  );
};

export default AppHeader;
