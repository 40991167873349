import React from 'react'

import Dropdown from './components/dropdown'

import style from './a.module.scss'

const ClassDates = () => {
	return (
		<div className={style.classDates}>
			<Dropdown/>
		</div>
	)
}

export default ClassDates
