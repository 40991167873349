import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import update from "immutability-helper";
import * as yup from "yup";


import Form from "./components/form";
import { getUserDetails, saveDetials } from "./contexts";
import checkValuesInUserDb from "./contexts";

const CreateClass = memo(() => {
  const { userName } = useParams();
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();


  const CheckUsername = async (username) => {
    if (username === userDetails.username) {
      return true;
    }
    let query = {
      username: username,
    };
    const res = await checkValuesInUserDb(query);
    return res;
  };

  const CheckPhoneNumber = async (phoneNumber) => {
    if (phoneNumber == userDetails.phone_number) {
      return true;
    }
    let query = {
      phone_number: phoneNumber,
    };
    const res = await checkValuesInUserDb(query);
    return res;
  };

  const saveDetails = async (details) => {
    let formData = {
      name: details.name,
      username: details.username,
      phone_number: details.phoneNumber,
    };
    const res = await saveDetials(formData);
    if (res) {
      navigate(`/user/${res.username}`);
    }
  };

  const formSchema = yup.object().shape({
    name: yup.string().required(),
    username: yup.string()
		.matches(/^[a-z0-9_]+$/, 'Username should only contain small alphabets, numbers, and underscores')
		.min(5, 'Username must be at least 5 characters long')
		.max(15, 'Username must be at most 15 characters long')
		.test('username validation', 'Username already exists', function(value) {
			return CheckUsername(value) || 'Username already exists';
		})
		.required('Username is required'),
    phoneNumber: yup
		.string()
		.matches(/^[0-9]{10}$/, 'Phone number should be exactly 10 digits')
		.test('phone number validation', 'Phone number already exists', function (value) {
			return CheckPhoneNumber(value) || 'Phone number already exists';
		})
		.required('Phone number is required'),
  });

  const [values, setValues] = useState({
    name: "",
    username: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    username: false,
    phoneNumber: false,
  });



  useEffect(() => {
    const getUser = async() => {
      const res= await getUserDetails(userName)
      setUserDetails(res[0])
      const updatedValue = {
        name: res[0].name,
        username: res[0].username,
        phoneNumber: res[0].phone_number,
      };
      setValues((values) => ({
        ...values,
        ...updatedValue,
      }));
    }
    getUser()
  }, []);

  const onFieldChange = useCallback((fieldName, value) => {
    setValues((prevValues) =>
      update(prevValues, {
        [fieldName]: {
          $set: value,
        },
      })
    );
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const isFormValid = await formSchema.isValid(values, {
        abortEarly: false,
      });
      console.log(errors);
      if (isFormValid) {
        saveDetails(values);
      } else {
        formSchema.validate(values, { abortEarly: false }).catch((err) => {
          const error = {};
          err.inner.forEach((err) => {
            error[err.path] = err.message;
          });
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: error,
            })
          );
        });
      }
    },
    [values]
  );

  return (
    <div>
      <Form
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
      />
    </div>
  );
});

export default CreateClass;
