// import stuff from react library
import { createContext } from "react";
// import stuff from other js libraries
import axios from "axios";
// import app configs
import { API_HOST, API_VERSION } from "../../../configs/EnvVariables.js";
// import utils
import { setLocalValue, getLocalValue } from "../../../utils/AppLocalStorage";

async function getClassDetails(id, classType) {
  let apiUrl = `${API_HOST}/api/${classType}/search`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
        "query": {
            "_id": id
        }
    }
    ),
  };

  let resData ;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
        resData = error;
    });
    return resData

}


async function updateClassDetails(data, classType) {
  let apiUrl = `${API_HOST}/api/${classType}`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
        resData = error;
    });
    return resData

}

export {updateClassDetails}
export default getClassDetails;
