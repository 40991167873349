// import stuff from react library
import { createContext } from "react";
// import stuff from other js libraries
import axios from "axios";
// import app configs
import { API_HOST, API_VERSION } from "../../../configs/EnvVariables";
// import utils
import { setLocalValue, getLocalValue } from "../../../utils/AppLocalStorage";

async function saveClass(classDetails, classType) {
  const userid = getLocalValue("appUser").id;
  classDetails["teacher_id"] = userid;
  let apiUrl = `${API_HOST}/api/${classType}`;
  const options = {
    method: "POST",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(classDetails),
  };

  let resData ;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => { 
      resData = data;
    })
    .catch((error) => {
        resData = error;
    });
    return resData
}


const saveClassInUser = async (classId, classType) => {
  const userId = getLocalValue('appUser').id
  const classIdKey = `${classType}_class_id`
  const data = {
    "id":userId,
  }
  data[classIdKey] = [classId];
  let apiUrl = `${API_HOST}/api/user`;
  const options = {
    method: "PUT",
    url: apiUrl,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let resData;
  await fetch(apiUrl, options)
    .then((response) => response.json())
    .then((data) => {
      resData = data;
    })
    .catch((error) => {
        resData = error;
    });
    return resData

};

export {saveClassInUser}

export default saveClass;
