import { memo, useCallback, useEffect, useState } from "react";
import style from '../../../a.module.scss'

import { Field } from "../../../../../components/AppField";

import AppPrimaryButton from "../../../../../components/AppPrimaryButton";

//import assets
import googleLogo from '../../../../../assets/Icons/google-logo.svg';

//imports from context
import { UserAuth } from "../../../../../contexts/AppFirebaseContext";


const Form = (props) => {
	
	const [userEmail, setUserEmail] = useState("")
	const [userPassword, setUserPassword] = useState("")

	//user authentication function 
	const {googleSignUp} = UserAuth()

	 //user authentication function 
	 const {googleSignIn, facebookSignIn, emailSignIn, emailSignUp, forgotPasword, logOut, errorMessage, user, loginLoading, setErrorMessage, isResetPasswordLinkSent} = UserAuth()
    
	const [passwordVisible, setPasswordVisible] = useState(false);
	const handleCheckboxChange = (event) => {
		setPasswordVisible(!passwordVisible );
	  };


	const OrComponent = () => {
		return <div className={style.orComponent}>
			<div></div>
			<p>Or</p>
			<div></div>
		</div>
	}

	const _handleGoogleSignIn = async () => {
        try{
            await googleSignIn();
        }
        catch(error){
            console.log(error)
        }
    }
	const _handleEmailSignIn = async (userEmail, userPassword) => {
        try{
            await emailSignIn(userEmail,userPassword);
        }
        catch(error){
            console.log(error)
        }
    }
	const _handleGoogleSignUp = async () => {
		try {
			await googleSignUp();
		}
		catch (error) {
			console.log(error)
		}
	}
	return (
		<div className={style.signInMain} >
			<form onSubmit={props.onSubmit} noValidate>
				<h1>Let's Get Started</h1>
				<h2>Sign-in to your account </h2>
				<div>
					<Field
						labelText="Email Id or Username"
						fieldType="text"
						fieldName="email"
						placeholder="john@gmail.com"
						fieldValue={props.values.email}
						hasError={props.errors.email}
						onFieldChange={props.onFieldChange}
					/>
					<Field
						labelText="Password"
						fieldType={passwordVisible ? 'text' : 'password'}
						fieldName="password"
						placeholder="password"
						fieldValue={props.values.password}
						hasError={props.errors.password}
						onFieldChange={props.onFieldChange}
					/>
					<div className={style.showPasswordDiv}>
						<div className={style.inputLabelSPB}>
							<input type="checkbox" id="markAllPaid" name="markAllPaid" onChange={handleCheckboxChange} ></input>
							<label htmlFor="markAllPaid">Show Password</label>
						</div>
						<a className={style.forgotPassword} href={'/signin/forgotpassword'}>Forgot Password ?</a>
					</div>
				</div>
				<div className={style.info}>Click "Sign In" to agree Yogamitra’s <a href={'/termsandconditions'} style={{ color: 'blue' }} >Terms of service </a> 
				and acknowledge that Yogamitra’s <a href={'/privacypolicy'} style={{ color: 'blue' }}>Privacy Policy </a> applies to you.</div>
                <div style={{marginTop:'0.3em'}} className={style.signInButtonDiv}>
					<button type="submit">
						<AppPrimaryButton title="Sign in" fill={true} />
					</button>
				</div>
                <OrComponent />
				<div style={{marginTop:'1em'}} onClick={_handleGoogleSignIn}><AppPrimaryButton title="Sign In with Google" imageSrc={googleLogo}/></div>
				<div className={style.signInText}>New to yogamitra? <a href={'/signup'} style={{ color: 'blue' }}>Sign Up</a></div>
			</form>
		</div>
	)
}

export default memo(Form)
