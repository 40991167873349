import { memo, useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import * as yup from "yup";


import Form from "./components/SignInForm";
import { UserAuth } from "../../../../contexts/AppFirebaseContext";



const SignIn = memo(() => {
	const {emailSignIn} = UserAuth()


	const [isSignInDetailsCorrect, setIsSignInDetailsCorrect] = useState(true)
	const [errorMessage, setErrorMessage] = useState("")


	const _handleEmailSignIn = async (userEmail, userPassword) => {
        try{
            let res = await emailSignIn(userEmail,userPassword);
			if(res.errorCode===200){
				setIsSignInDetailsCorrect(true)
			}
			else{
				setIsSignInDetailsCorrect(false)
				setErrorMessage(res.errorMessage)
			}
        }
        catch(error){
            console.log(error)
        }
    }

	useEffect(()=>{
		console.log(errorMessage)
	},[errorMessage])

	useEffect(()=>{
	},[isSignInDetailsCorrect])
    
	const formSchema = yup.object().shape({
		email: yup.string().email('*Invalid email address')
		.required('*Email is required')
		.test('Username/Password is incorrect', 'Username/Password is incorrect', function(value) {
            return isSignInDetailsCorrect
        }),
		password: yup
		.string()
		.required('*Password is required')
		.test('Username/Password is incorrect', 'Username/Password is incorrect', function(value) {
            return isSignInDetailsCorrect
        })
	});

	const [values, setValues] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [errors, setErrors] = useState({
		email: false,
		password: false,
		confirmPassword: false,
	});

	const onFieldChange = useCallback((fieldName, value) => {
		// console.log(fieldName, value);
		setValues((prevValues) =>
		update(prevValues, {
			[fieldName]: {
			$set: value,
			},
		})
		);
	}, []);

	const onSubmit = useCallback(
		async (event) => {
		event.preventDefault();
		const isFormValid = await formSchema.isValid(values, {
			abortEarly: false,
		});
		if (isFormValid) {
			_handleEmailSignIn(values.email,values.password)
		} else {
			formSchema.validate(values, { abortEarly: false }).catch((err) => {
            const error = {};
            err.inner.forEach((err) => {
                error[err.path] = err.message;
            });
			setErrors((prevErrors) =>
				update(prevErrors, {
				$set: error,
				})
			);
			console.log(error)
			});
		}
		},
		[values]
	);

	return (
		<div>
			<Form
				values={values}
				errors={errors}
				onFieldChange={onFieldChange}
				onSubmit={onSubmit}
                formSchema={formSchema}
			/>
		</div>
	);
});

export default SignIn;