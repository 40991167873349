import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './themes/variables.css';
import { AuthContextProvider } from "./contexts/AppFirebaseContext";
import { UserContextProvider } from './contexts/AppUserDetailContext';
import { UserAboutContextProvider } from './contexts/AppUserAboutContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <UserContextProvider>
        <UserAboutContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </UserAboutContextProvider>
      </UserContextProvider>
    </Router>
  </React.StrictMode>
);

